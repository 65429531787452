import React, { useState } from "react";
import { faMessage,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles } from './styles'
import './Chat.css'

const Avatar = props => {
    const [hovered, setHovered] = useState(false)

    return (
        <div style={props.style}>
            <div 
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => props.onClick && props.onClick()}
                className='transition-3 '
                style={{
                    ...styles.chatWithMeButton,
                    ...{ border: hovered ? '1px solid var(--main-color)' : '4px solid var(--main-color)' }
                }}
            >
                <FontAwesomeIcon className="icon-style" icon={faEnvelope} />

            </div>
        </div>
    )
}

export default Avatar;