import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./ChangePassword.css";
import { Auth } from "aws-amplify";
import Validate from "../../components/utility/FormValidation.js";
import Alert from 'react-bootstrap/Alert';


const ChangePassword = () => {

  const [show, setShow] = useState(false);
  const [someErrore,setSomeErrore]=useState("")

  const clearErrorState = () => {
    setStatus({
      ...status,
      ...{
        errors: {
          cognito: null,
          blankfield: false,
          passwordmatch: false,
        },
      },
    });
  };

  const validate = () => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!status.newpassword) {
      errors.newpassword = "new password is required!";
      document.getElementById("newpassword").classList.add("is-danger");
    }
    if (!status.password) {
      errors.password = "Password is required!";
      document.getElementById("password").classList.add("is-danger");
    }
    if (!status.confirmpassword) {
      errors.confirmpassword = "confirmpassword is required!";
      document.getElementById("confirmpassword").classList.add("is-danger");
    }

    return errors;
  };

  const [formErrors, setFormErrors] = useState();
  


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    clearErrorState();
    if (status.newpassword !== status.confirmpassword) {
    }
    const validationErrors=validate();
    setFormErrors(validationErrors)
    // const error = Validate(event, status);
    // if (error) {
    //   setStatus({
    //     ...status,
    //     ...{
    //       errors: { ...status.errors, ...error },
    //     },
    //   });
    // }

    // AWS Cognito integration here
    try {
      const user = await Auth.currentAuthenticatedUser();
      const signUpResponse = await Auth.changePassword(
        user,
        status.password,
        status.newpassword
      );
      document.getElementById("password").value="";
      document.getElementById("newpassword").value="";
      document.getElementById("confirmpassword").value="";
    } catch (error) {
      setShow(true)
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setStatus({
        ...status,
        ...{
          errors: {
            ...status.errors,
            cognito: err,
          },
        },
      });
      setSomeErrore(error.message)
    }
  };

  const [status, setStatus] = useState({
    password: "",
    newpassword: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false,
    },
  });

  const onInputChange = (event) => {
    setStatus({ ...status, ...{ [event.target.id]: event.target.value } });
    // document.getElementById(event.target.id).classList.remove("is-danger");
    setFormErrors({ ...formErrors, ...{ [event.target.id]: '' } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };
  return (
    <div className="change__password__form">
      <Form onSubmit={handleSubmit}>
        <h3>Change Password</h3>
        <br></br>

        <Form.Group
          className="change__password__FormGroup"
          controlId="password"
        >
          <Form.Control
            type="password"
            placeholder="Old Password"
            value={status.password}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.password}</p>

        <Form.Group
          className="change__password__FormGroup"
          controlId="newpassword"
        >
          <Form.Control
            type="password"
            placeholder="New password"
            value={status.newpassword}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.newpassword}</p>
        <Form.Group
          className="change__password__FormGroup"
          controlId="confirmpassword"
        >
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={status.confirmpassword}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.confirmpassword}</p>
        <Form.Group
          className="change__password__FormGroup"
          controlId="formBasicName"
        >
          {/* <Button variant="success" size="nd" block="true" type="submit">
            Change
          </Button> */}
          {!show && <Button  variant="success-c" size="nd" block="true" type="submit" >Change</Button>}
        </Form.Group>
      </Form>
      
      <Alert show={show} variant="success">
        <Alert.Heading>Error Message</Alert.Heading>
        <input className="popUpInput" id="someErrore" value={someErrore} disabled variant="success" ></input>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close
          </Button>
        </div>
      </Alert>
    </div>
  );
};
export default ChangePassword;
