import { createContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { USER_TYPE_SELLER } from "../store/constants";
import { GetUserUnreadNotifications } from "../store/services/user";
import getTasksList from "../helpers/tasksList";
import { GetUserInvitationsTasks } from "../store/services/tasks";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    User: null,
    AuthStatus: false,
    isAuthReady: false,
    UserType: USER_TYPE_SELLER,
    notifications: [],
    invitations: [],
  });

  const getsesstion = async () => {
    try {
      const session = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const getUserNotifications = await GetUserUnreadNotifications(
        user.attributes?.email
      );
      const userType = user.attributes["custom:AcountType"];
      const notificationsList = getUserNotifications?.data?.Items || [];
      let invitationsList = [];
      if (userType === USER_TYPE_SELLER) {
        const getUserInvitations = await GetUserInvitationsTasks(
          user.attributes?.email
        );
        invitationsList = getTasksList(getUserInvitations);
      }
      setAuth({
        User: user,
        UserType: user.attributes["custom:AcountType"],
        AuthStatus: true,
        isAuthReady: true,
        notifications: notificationsList,
        invitations: invitationsList,
      });
    } catch (error) {
      if (error !== "No current user") {
      }
      setAuth({ ...auth, ...{ isAuthReady: true } });
    }
  };

  useEffect(() => {
    const unsub = getsesstion(() => {
      unsub();
    });
  }, []);
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
