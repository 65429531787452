import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import "./Header.css";
import AuthContext from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import {
  ChangeUserNotifications,
  GetUserProfileImage,
} from "../../store/services/user";
import {
  USER_PROFILE_STATUS_CREATED,
  USER_TYPE_ADMIN,
  USER_TYPE_BUYER,
  USER_TYPE_SELLER,
} from "../../store/constants";

const Header = () => {
  const [navbar, setNavbar] = useState();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      authContext?.setAuth({
        User: null,
        AuthStatus: false,
        isAuthReady: true,
      });
      navigate("/");
    } catch (error) {}
  };
  const handleNotifications = async () => {
    const newNotifications = authContext?.auth?.notifications.map((obj) => {
      return { ...obj, status: "read" };
    });
    await ChangeUserNotifications(newNotifications);
  };
  const bill = <FontAwesomeIcon icon={faBell} size="lg" />;
  const userpic = (
    <img
      width="35px"
      height="35px"
      className="userimg"
      alt="userimagse"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
      }}
      src={GetUserProfileImage(authContext?.auth?.User?.attributes?.sub)}
    />
  );
  window.addEventListener("scroll", changeBackground);
  return (
    <div>
      <div className={navbar ? "nn active" : "nn"}>
        <Row className="containerh">
          <Col className="col1">
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              color={navbar ? "black" : "white"}
              id="baricon"
            />
            <a href="https://arabfcn.net/">
              <div className="nm">
                <div className="logo">
                  <img
                    alt="logo"
                    src="https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/AFCN+Logo.png"
                  />
                  <div className="dot"></div>
                </div>
              </div>
            </a>
            <div className="disactive">
              <div className="dot"></div>
            </div>
          </Col>
          <Col className="col2">
            <ul className="nvegation">
              {authContext?.auth?.AuthStatus &&
                authContext?.auth?.UserType === USER_TYPE_SELLER &&
                localStorage.getItem("profile-status") ===
                  USER_PROFILE_STATUS_CREATED && (
                  <li className="ll">
                    <div className="icon">
                      <a
                        className={navbar ? "linkcolor" : "disactivecolor"}
                        href="/jobs/invitations"
                      >
                        Invitations
                      </a>
                      <div className="counter">
                        {authContext?.auth?.invitations.length}
                      </div>
                    </div>
                  </li>
                )}
              {authContext?.auth?.AuthStatus &&
                authContext?.auth?.UserType !== USER_TYPE_ADMIN &&
                localStorage.getItem("profile-status") ===
                  USER_PROFILE_STATUS_CREATED && (
                  <li className="ll">
                    <a
                      className={navbar ? "linkcolor" : "disactivecolor"}
                      href="/jobs/user-jobs"
                    >
                      My Jobs
                    </a>
                  </li>
                )}
              {authContext?.auth?.AuthStatus &&
                authContext?.auth?.UserType === USER_TYPE_ADMIN && (
                  <li className="ll">
                    <a
                      className={navbar ? "linkcolor" : "disactivecolor"}
                      href="/join-requests"
                    >
                      Join Requests
                    </a>
                  </li>
                )}
              {authContext?.auth?.AuthStatus &&
                authContext?.auth?.UserType !== USER_TYPE_BUYER &&
                localStorage.getItem("profile-status") ===
                  USER_PROFILE_STATUS_CREATED && (
                  <li className="ll">
                    <a
                      className={navbar ? "linkcolor" : "disactivecolor"}
                      href="/jobs/new"
                    >
                      Marketplace
                    </a>
                  </li>
                )}

              <li className="tli">
                <a
                  className={navbar ? "linkcolor" : "disactivecolor"}
                  href="/AboutUs"
                >
                  About Us
                </a>
              </li>
              <li className="tli">
                <a
                  className={navbar ? "linkcolor" : "disactivecolor"}
                  href="/ContactUs"
                >
                  Contact Us
                </a>
              </li>
              {!authContext?.auth?.AuthStatus && (
                <li className="tli">
                  <a
                    className={navbar ? "linkcolor" : "disactivecolor"}
                    href="/signin"
                  >
                    Sign In
                  </a>
                </li>
              )}
              {!authContext?.auth?.AuthStatus && (
                <li className="bli">
                  <a href="/join">
                    <Button variant="outline" color="red">
                      Join
                    </Button>
                  </a>
                </li>
              )}
              {authContext?.auth?.AuthStatus && (
                <div className="notifications">
                  <NavDropdown
                    title={bill}
                    id="userNotifications"
                    onClick={handleNotifications}
                    disabled={authContext?.auth?.notifications.length === 0}
                  >
                    {authContext?.auth?.notifications.map((item, index) => (
                      <NavDropdown.Item key={index}>
                        <div className="header__notification__item">
                          <p className="header__notification__item__message">
                            {item.message}
                          </p>
                          <p className="header__notification__item__date">
                            {item.date}
                          </p>
                        </div>
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  {authContext?.auth?.notifications?.length > 0 && (
                    <div className="counter">
                      {authContext?.auth?.notifications.length}
                    </div>
                  )}
                </div>
              )}
              {authContext?.auth?.AuthStatus && (
                <div className="username">
                  <NavDropdown title={userpic} id="username">
                    <LinkContainer to="/user-profile">
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/change-password">
                      <NavDropdown.Item>Change Password</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/">
                      <NavDropdown.Item onClick={handleLogOut}>
                        Logout
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </div>
              )}
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
