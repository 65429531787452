import React, { useContext, useEffect, useState } from "react";
import "./JoinRequestDetails.css";
import Loading from "../../components/loading/loading";
import { AlertDialog } from "../../components/dialog/alertDialog";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  PREFERED_TOPIC_OTHER,
  USER_TYPE_SELLER,
  YES,
  ORGANIZATION_TYPE_OTHER,
  USER_ACTION_ACCEPT,
  USER_ACTION_REJECT,
} from "../../store/constants";
import {
  getGender,
  getLanguageProficiency,
  getPreferedTopic,
  getUserTypeText,
  getUserOrganizationType,
} from "../../helpers/userProfile";
import { decrypt } from "../../helpers/encrypt";
import { Auth } from "aws-amplify";
import {
  AddUserProfile,
  ChangeJoinRequestStatus,
  DeleteJoinRequest,
} from "../../store/services/user";
import { sesSandboxSendEmail } from "../../store/services/SES";
import { getCountryByIso2 } from "../../utl/countries";

const JoinRequestDetails = (props) => {
  const [dialogMassage, setDialogMassage] = useState({
    massageTitle: "Error",
    massageContent: "",
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  const [showOrganizationTypeOther, setShowOrganizationTypeOther] =
    useState(false);
  const [showTopicOther, setShowTopicOther] = useState(false);
  const [prossessed, setProssessed] = useState(false);
  const [showWorkerExtraData, setShowWorkerExtraData] = useState("none");
  const [showOwnerExtraData, setShowOwnerExtraData] = useState("none");
  useEffect(() => {
    if (props.user.usertype === USER_TYPE_SELLER) {
      setShowWorkerExtraData("block");
      if (props.user.work === YES) {
        setShowOrganization(true);
      }
      if (props.user.topic === PREFERED_TOPIC_OTHER) {
        setShowTopicOther(true);
      }
    } else {
      setShowOwnerExtraData("block");
      if (props.user.organizationType === ORGANIZATION_TYPE_OTHER) {
        setShowOrganizationTypeOther(true);
      }
    }
  }, [setShowWorkerExtraData, setShowOwnerExtraData]);

  const handleSubmit = async (event) => {
    document.getElementById("loaing").classList.remove("hidden__input");
    try {
      const username = props.user.username;
      const password = decrypt(props.user.userString);
      const email = props.user.email;
      const accountType = props.user.usertype;
      let action = event.target.id;
      const userProfile = {
        first_name: "",
        last_name: "",
        email: email,
        username: username,
        accountType: accountType,
        phone_number: props.user.phone,
        cognitoId: "",
        country: props.user.organizationCountry,
        facebook: props.user.facebook,
        linkedin: props.user.linkedin,
        gender: props.user.gender,
        experience: props.user.experience,
        work: props.user.work,
        organization: props.user.organization,
        english: props.user.english,
        arabic: props.user.arabic,
        topic: props.user.topic,
        topicOther: props.user.topicOther,
        linkone: props.user.linkone,
        linktwo: props.user.linktwo,
        linkthree: props.user.linkthree,
        organizationName: props.user.organizationName,
        organizationWebsite: props.user.organizationWebsite,
        organizationType: props.user.organizationType,
        organizationTypeOther: props.user.organizationTypeOther,
      };
      if (action === USER_ACTION_ACCEPT) {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email: email,
            "custom:AcountType": accountType,
          },
        });
        await AddUserProfile(userProfile);
      }
      await ChangeJoinRequestStatus(email, action);
      sesSandboxSendEmail(userProfile, action, [email], "");
      setProssessed(true);
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Info",
        massageContent: "Operation Completed Successfully",
      });
    } catch (error) {
      let err = null;
      !error.message ? (err = error) : (err = error.message);
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: err,
      });
    } finally {
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };

  return (
    <div className="join__request__details">
      <div className="hidden__input" id="loaing">
        <Loading />
      </div>

      <div className="join__request__details__content">
        <Container className="join__container">
          <Row>
            <div className="join__content">
              Account Type
              <p className="join__request__details__disabled__content">
                {getUserTypeText(props.user.usertype)}
              </p>
            </div>
          </Row>
          <Row>
            <Col lg="6">
              <div className="join__content">
                Email
                <p className="join__request__details__disabled__content">
                  {props.user.email}
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div className="join__content">
                Username
                <p className="join__request__details__disabled__content">
                  {props.user.username}
                </p>
              </div>
            </Col>
          </Row>
          <div
            className="join__extra__data"
            style={{ display: showOwnerExtraData }}
          >
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Organization Name
                  <p className="join__request__details__disabled__content">
                    {props.user.organizationName}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  Organization Country
                  <p className="join__request__details__disabled__content">
                    {getCountryByIso2(props.user.organizationCountry).name}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Organization Website
                  <a href={props.user.organizationWebsite} target="_blank">
                    <p className="join__request__details__disabled__content">
                      {props.user.organizationWebsite}
                    </p>
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  Organization Type
                  <p className="join__request__details__disabled__content">
                    {getUserOrganizationType(props.user.organizationType)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content"></div>
              </Col>
              <Col lg="6">
                {showOrganizationTypeOther && (
                  <div className="join__content">
                    Organization Type
                    <p className="join__request__details__disabled__content">
                      {props.user.organizationTypeOther}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <div
            className="join__extra__data"
            style={{ display: showWorkerExtraData }}
          >
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Phone
                  <p className="join__request__details__disabled__content">
                    {props.user.phone}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  facebook
                  <a href={props.user.facebook} target="_blank">
                    <p className="join__request__details__disabled__content">
                      {props.user.facebook}
                    </p>
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Linkedin
                  <a href={props.user.linkedin} target="_blank">
                    <p className="join__request__details__disabled__content">
                      {props.user.linkedin}
                    </p>
                  </a>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Gender
                  <p className="join__request__details__disabled__content">
                    {getGender(props.user.gender)}
                  </p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Years of experience
                  <p className="join__request__details__disabled__content">
                    {props.user.experience}
                  </p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Are you Working with a Fact Checking organization?
                  <p className="join__request__details__disabled__content">
                    {props.user.work === YES ? "Yes" : "No"}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                {showOrganization && (
                  <div className="join__content">
                    Organization name
                    <p className="join__request__details__disabled__content">
                      {props.user.organization}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  English proficiency
                  <p className="join__request__details__disabled__content">
                    {getLanguageProficiency(props.user.english)}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  Arabic proficiency
                  <p className="join__request__details__disabled__content">
                    {getLanguageProficiency(props.user.arabic)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Prefered topic
                  <p className="join__request__details__disabled__content">
                    {getPreferedTopic(props.user.topic)}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                {showTopicOther && (
                  <div className="join__content">
                    Prefered topic
                    <p className="join__request__details__disabled__content">
                      {getPreferedTopic(props.user.topicOther)}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Links to items you fact checked</div>
              </Col>
            </Row>
            <Row>
              <div className="join__content">
                <a href={props.user.linkone} target="_blank">
                  <p className="join__request__details__disabled__content">
                    {props.user.linkone}
                  </p>
                </a>
              </div>
            </Row>
            <Row>
              <div className="join__content">
                <a href={props.user.linktwo} target="_blank">
                  <p className="join__request__details__disabled__content">
                    {props.user.linktwo}
                  </p>
                </a>
              </div>
            </Row>
            <Row>
              <div className="join__content">
                <a href={props.user.linkthree} target="_blank">
                  <p className="join__request__details__disabled__content">
                    {props.user.linkthree}
                  </p>
                </a>
              </div>
            </Row>
          </div>
        </Container>
        <div className="join__request__details__footer">
          <div className="join__request__details__footer__content">
            <Button
              variant="success-c"
              size="nd"
              block="true"
              id={USER_ACTION_ACCEPT}
              onClick={(e) => handleSubmit(e)}
              disabled={prossessed}
            >
              Accept
            </Button>
          </div>
          <div className="join__request__details__footer__content">
            <Button
              id={USER_ACTION_REJECT}
              variant="success-c"
              size="nd"
              block="true"
              onClick={(e) => handleSubmit(e)}
              disabled={prossessed}
            >
              Reject
            </Button>
          </div>
        </div>
      </div>
      <AlertDialog
        show={showDialog}
        title={dialogMassage.massageTitle}
        content={dialogMassage.massageContent}
        agreeAction={"Ok"}
        disagreeAction={"cancel"}
        handleDisagree={() => setShowDialog(false)}
        handleAgree={() => setShowDialog(false)}
      ></AlertDialog>
    </div>
  );
};

export default JoinRequestDetails;
