import React from "react";
import "./JoinRequestListing.css";
import { getUserTypeText } from "../../helpers/userProfile";
const JoinRequestListing = ({ user }) => {
  return (
    <div className="join__request__listing">
      <div className="join__request__listing__content">
        <div className="join__request__listing__content__text">
          <div className="join__request__listing__content__text__title">
            <h2>{user.username}</h2>
          </div>
          <div className="join__request__listing__content__descripttion">
            <p>{user.email}</p>
          </div>
          <div className="join__request__listing__content__descripttion">
            <p>{getUserTypeText(user.usertype)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinRequestListing;
