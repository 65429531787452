import { useRef, useState, useEffect ,useContext} from "react";
import "./RoundImagePreview.css";

export const RoundImagePreview = ({ onChange, id, value }) => {
  const [image, setImage] = useState();
  const [preview, setPreview] = useState(value);
  const fileInputRef = useRef();

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImage(file);
    } else {
      setImage(null);
    }
    onChange(event);
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(value);
    }
  }, [image]);

  return (
    <div>
      {preview ? (
        <img
          src={preview}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
          }}
          style={{ objectFit: "cover" }}
          className="round"
          onClick={(e) => {
            handleClick(e);
          }}
        />
      ) : (
        <button className="round" onClick={(e) => handleClick(e)}>
          Add Image
        </button>
      )}
      <input
        id={id}
        className="hidden__input"
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={(e) => handleInputChange(e)}
        // value={value}
      />
    </div>
  );
};
