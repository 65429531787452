import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Col, Row, Carousel } from "react-bootstrap";
import AboutUsPartnerInfo from "../../components/aboutUsPartnerInfo/aboutUsPartnerInfo";
import {
  GetS3FilePath,
  ListFilesFromS3,
} from "../../store/services/uploadImageToS3";
import "./AboutUs.css";

const AboutUs = () => {
  const [ownersImages, setOwnersImages] = useState([]);
  const [partnersImages, setPartnersImages] = useState([]);
  useEffect(() => {
    ListFilesFromS3("assets/owners/", (err, data) => {
      if (err) {
      } else {
        setOwnersImages(data.Contents);
      }
    });
    ListFilesFromS3("assets/partners/", (err, data) => {
      if (err) {
      } else {
        setPartnersImages(data.Contents);
      }
    });
  }, [setOwnersImages, setPartnersImages]);
  return (
    <div className="about__us">
      <Container>
        <Col xs lg="12">
          <Row>
            <h1 className="about__us__header">About the project</h1>
            <p>
              SA7 is a project by Arab Fact-Checkers Network (AFCN) & ARIJ, with
              the support of the "Qarib" regional program implemented by the
              French Media Development Agency (CFI) and funded by the French
              Development Agency (AFD)
            </p>
            <p>
              #SA7 project seeks to establish a network of organizations and
              individuals in 4 countries: Lebanon, Jordan, Palestine, and Iraq,
              who will work together to combat the spread of mis/disinformation
              by promoting pre and post fact-checking best practices and
              instilling the concept of fact-checking within Arab media outlets
            </p>
            <p>
              The project focuses on developing the code of principles for
              fact-checking in the Arab world, training and developing
              methodologies and skills for fact-checking through workshops and
              webinars, designing educational materials and awareness campaigns,
              organising a regional track on fact-checking, and developing tools
              and data sources in Arabic to support the work of fact-checkers
            </p>
            <hr className="about__us__line"/>
          </Row>
          <Row>
            <h1 className="about__us__header">Project Of</h1>
          </Row>
          <Row>
            <div className="about__us__ownners__slider">
              {ownersImages.map(
                (item, index) =>
                  index > 0 && (
                    <img src={GetS3FilePath(item.Key)} alt="slider" />
                  )
              )}
            </div>
          </Row>
          <Row>
            <h1 className="about__us__header">Our Partners</h1>
          </Row>
          <Row>
            <div className="about__us__slider">
              <Carousel className="about__us__slider__carousel">
                {partnersImages.map(
                  (item, index) =>
                    index > 0 && (
                      <Carousel.Item key={index}>
                        <img src={GetS3FilePath(item.Key)} alt="slider" />
                      </Carousel.Item>
                    )
                )}
              </Carousel>
            </div>
          </Row>
          <Row>
            <AboutUsPartnerInfo
              title="About ARIJ"
              content="ARIJ has long been on the frontline of investigative journalism
            in the MENA region, providing theoretical and hands-on training
            to journalists at different career stages. Since 2006, it has
            trained 3500 journalists, editors and fact checkers and
            supported over 650 quality investigations. In the past five
            years, it won over 60 local, regional and international awards"
              image={GetS3FilePath("assets/owners/arij-black.png")}
              link="https://arij.net/"
            />
            <hr className="about__us__line" />
          </Row>
          <Row>
            <AboutUsPartnerInfo
              title="About AFCN"
              content="The Arab Fact-Checkers Network (AFCN) established in December 2020
              and led by ARIJ, is a grassroots network that works towards
              fostering transparent and impartial fact-checking in the Arab
              region. This network is a product of the mis/disinformation spikes
              amid the unprecedented times of COVID-19 worldwide"
              image={GetS3FilePath("assets/owners/afcn.png")}
            />
            <hr className="about__us__line" />
          </Row>
          <Row>
            <AboutUsPartnerInfo
              title="About CFI"
              content="CFI is a public sector operator funded primarily by the French
              Ministry of Europe and Foreign Affairs. A subsidiary of the France
              Médias Monde group, CFI actively promotes the development of the
              media in Africa, the Arab world and South-East Asia"
              image={GetS3FilePath("assets/partners/cfi.png")}
            />
            <hr className="about__us__line" />
          </Row>
          <Row>
            <AboutUsPartnerInfo
              title="About AFD"
              content="The Agence Française de Développement (AFD) Group funds, supports
              and accelerates the transition to a fairer and more sustainable
              world. Focusing on climate, biodiversity, peace, education, urban
              development, health and governance, our teams carry out more than
              4,000 projects in France’s overseas departments and territories
              and another 115 countries"
              image={GetS3FilePath("assets/partners/afd.png")}
            />
            <hr className="about__us__line" />
          </Row>
          <Row>
            <AboutUsPartnerInfo
              title="About Qarib"
              content=" Qarib is a regional programme implemented by CFI, the French media
              development agency and funded by AFD, the French development
              agency. Qarib supports media outlets operating in Jordan, Lebanon,
              Iraq and Palestine in order to boost social cohesion, promote the
              integration of communities and encourage public debate"
              image={GetS3FilePath("assets/partners/qarib.png")}
            />
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default AboutUs;
