import Userdetail from "../../components/UserDetails/userdetail.js";
import "./JoinRequestInfo.css";
import { useLocation } from "react-router-dom";
import JoinRequestDetails from "../../containers/JoinRequestDetails/joinRequestDetails.js";
export const JoinRequestInfo = () => {
  const location = useLocation();
  let user = {};
  user = location.state.user;
  return (
    <div className="join__request__Info__main">
      <div className="join__request__Info__content">
        <div className="join__request__Info__userdetails">
          <Userdetail />
        </div>
        <div className="join__request__Info__jobdetails">
          <JoinRequestDetails user={user} />
        </div>
      </div>
    </div>
  );
};
