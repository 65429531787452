import { decrypt, encrypt } from "../../helpers/encrypt";
import { USER_ACTION_REJECT } from "../constants";
import API from "./API";
import { GetS3FilePath } from "./uploadImageToS3";

export const GetUserProfile = async (userEmail) => {
  return API.get(`/getube`, {
    params: {
      email: userEmail,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const GetUsersByRole = async (role) => {
  return API.get(`/getubr`, {
    params: {
      role: role,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const AddUserProfile = async (userProfile) => {
  var data = {
    userfn: userProfile.first_name,
    userln: userProfile.last_name,
    useremail1: userProfile.email,
    username: userProfile.username,
    userrole: userProfile.accountType,
    userpn: userProfile.phone_number,
    userc: userProfile.country,
    taskInvitaion: [""],
    subscription: [""],
    cognitoId: userProfile.cognitoId,
    facebook: userProfile.facebook,
    linkedin: userProfile.linkedin,
    gender: userProfile.gender,
    experience: userProfile.experience,
    work: userProfile.work,
    organization: userProfile.organization,
    english: userProfile.english,
    arabic: userProfile.arabic,
    topic: userProfile.topic,
    topicOther: userProfile.topicOther,
    linkone: userProfile.linkone,
    linktwo: userProfile.linktwo,
    linkthree: userProfile.linkthree,
    organizationName: userProfile.organizationName,
    organizationWebsite: userProfile.organizationWebsite,
    organizationType: userProfile.organizationType,
    organizationTypeOther: userProfile.organizationTypeOther,
  };
  return API.post(`/addu`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const AddJoinRequest = async (userProfile) => {
  const encString = encrypt(userProfile.userString);
  var data = {
    email: userProfile.email,
    username: userProfile.username,
    usertype: userProfile.usertype,
    userString: encString,
    phone: userProfile.phone,
    facebook: userProfile.facebook,
    linkedin: userProfile.linkedin,
    gender: userProfile.gender,
    experience: userProfile.experience,
    work: userProfile.work,
    organization: userProfile.organization,
    english: userProfile.english,
    arabic: userProfile.arabic,
    topic: userProfile.topic,
    topicOther: userProfile.topicOther,
    linkone: userProfile.linkone,
    linktwo: userProfile.linktwo,
    linkthree: userProfile.linkthree,
    organizationName: userProfile.organizationName,
    organizationCountry: userProfile.organizationCountry,
    organizationWebsite: userProfile.organizationWebsite,
    organizationType: userProfile.organizationType,
    organizationTypeOther: userProfile.organizationTypeOther,
    status: userProfile.status,
  };
  return API.post(`/joinrequest`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetAllJoinRequests = async (status) => {
  return API.get(`/getallreq`, {
    params: {
      status: status,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const DeleteJoinRequest = async (email) => {
  return API.delete(`/deletejoinreq`, {
    data: {
      email: email,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const AddUserNotification = async (email, status, message, date) => {
  var data = {
    email: email,
    status: status,
    message: message,
    date: date,
  };
  return API.post(`/addnotification`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetUserUnreadNotifications = async (userEmail) => {
  return API.get(`/getnotifications`, {
    params: {
      email: userEmail,
      status: "unread",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const ChangeUserNotifications = async (notifications) => {
  var data = {
    notifications: notifications,
  };
  return await API.put(`/changeUserNotificationStatus`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const Subscribe = async (ownerEmail, workerEmail) => {
  var data = {
    email: ownerEmail,
    subscriber: workerEmail,
  };
  return await API.put(`/subscribe`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const Unsubscribe = async (ownerEmail, workerEmail) => {
  var data = {
    email: ownerEmail,
    subscriber: workerEmail,
  };
  return await API.put(`/unsub`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const ChangeJoinRequestStatus = async (email, status) => {
  let action = "accept";
  if (status === USER_ACTION_REJECT) {
    action = "reject";
  }
  var data = {
    email: email,
    action: action,
  };
  return await API.put(`/changejoinsts`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetUserProfileImage = (userProfileId) => {
  return GetS3FilePath(userProfileId + "/profile-image/profileImage.png");
};
