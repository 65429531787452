import React from "react";
import { CDBFooter, CDBBox, CDBBtn, CDBIcon } from "cdbreact";
import "./Footer.css";

const Footer = () => {
  return (
    <CDBFooter className="shadow footer-style">
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="mx-auto py-4 flex-wrap"
        style={{ width: "80%" }}
      >
        <CDBBox display="flex" alignItems="center">
          <a
            href="/"
            className="d-flex align-items-center p-0 text-dark"
            id="market-link"
          >
            <img
              alt="logo"
              src="https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/AFCN+Logo.png"
              width="30px"
            />
            <span className="ml-4 h5 mb-0 font-weight-bold">Marketplace</span>
          </a>
        </CDBBox>
        <CDBBox display="flex" alignItems="center">
          <small className="copy-right-style">
            &copy; 2022. All rights reserved.
          </small>
        </CDBBox>
        <CDBBox display="flex">
          <form action="https://www.facebook.com/ARIJNetwork/" target="_blank">
            <CDBBtn flat color="dark" className="p-2" type="submit">
              <CDBIcon fab icon="facebook-f" />
            </CDBBtn>
          </form>
          <form
            action="https://mobile.twitter.com/arabfcnetwork"
            target="_blank"
          >
            <CDBBtn flat color="dark" className="mx-3 p-2" type="submit">
              <CDBIcon fab icon="twitter" />
            </CDBBtn>
          </form>
          <form action="https://www.instagram.com/arijnetwork/" target="_blank">
            <CDBBtn flat color="dark" className="p-2" type="submit">
              <CDBIcon fab icon="instagram" />
            </CDBBtn>
          </form>
        </CDBBox>
      </CDBBox>
    </CDBFooter>
  );
};

export default Footer;
