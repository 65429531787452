import { TASK_ACTION_ACCEPT, TASK_ACTION_APPLY } from "../constants";
import API from "./API";
import { GetS3FilePath } from "./uploadImageToS3";

export const AddTask = async (
  title,
  description,
  image,
  price,
  deadline,
  attachments,
  email
) => {
  var data = {
    worker: " ",
    title: title,
    description: description,
    image: image,
    price: price,
    period: deadline,
    attachments: attachments,
    owner: email,
    genres: ["images"],
    applicants: [],
    invitations: [""],
    payment: {},
    taskState: "new",
    taskComment: [""],
    taskConclusions: [""],
  };
  return await API.post(`/addt`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetOwnerTasks = async (status, email) => {
  return await API.get(`/gtbo`, {
    params: {
      state: status,
      usertype: 3,
      email: email,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetAllOwnerTasks = async (email) => {
  return await API.get(`/gtbo`, {
    params: {
      usertype: 1,
      email: email,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetWorkerTasks = async (status, email) => {
  return await API.get(`/gtbw`, {
    params: {
      state: status,
      type: 3,
      email: email,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const GetAllWorkerTasks = async (email) => {
  return await API.get(`/gtbw`, {
    params: {
      type: 1,
      email: email,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const GetAllTasks = async (status) => {
  return await API.get(`/getats`, {
    params: {
      state: status,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const Apply = async (
  id,
  email,
  proposal,
  price,
  applicantUserId,
  applicantUsername
) => {
  var data = {
    id: id,
    newData: {
      email: email,
      propsal: proposal,
      price: price,
      applicantUserId: applicantUserId,
      applicantUsername: applicantUsername,
    },
    action: TASK_ACTION_APPLY,
  };
  return await API.put(`/upapp`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const AcceptWorker = async (id, email) => {
  var data = {
    id: id,
    worker: email,
    action: TASK_ACTION_ACCEPT,
  };
  return await API.put(`/upapp`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
export const GetTaskProfileImage = (taskId) => {
  if (taskId) {
    return GetS3FilePath(taskId + "/profile-image/profileImage.png");
  } else {
    return "";
  }
};

export const ChangeState = async (id, action) => {
  var data = {
    id: id,
    action: action,
  };
  return await API.put(`/changestate`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const UpdateWorkerTask = async (id, conclusions, action) => {
  var data = {
    id: id,
    newConclusions: conclusions,
    action: action,
  };
  return await API.put(`/uptc`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const UpdateWorkerTaskComment = async (id, comment, action) => {
  var data = {
    id: id,
    newComment: comment,
    action: action,
  };
  return await API.put(`/uptc`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const UpdatePayment = async (taskId, tarnsactionId) => {
  var data = {
    id: taskId,
    tarnsactionId: tarnsactionId,
  };
  return await API.put(`/updatepayment`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const InviteWorker = async (taskId, workerEmail) => {
  var data = {
    taskId: taskId,
    email: workerEmail,
  };
  return await API.put(`/invite`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const GetUserInvitationsTasks = async (email) => {
  var data = {
    email: email,
  };
  return await API.put(`/getinvetations`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const GetTaskById = async (taskId) => {
  return await API.get(`/gettbi`, {
    params: {
      id: taskId,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const RemoveInvitation = async (taskId, workerEmail) => {
  var data = {
    taskId: taskId,
    email: workerEmail,
  };
  return await API.put(`/removeinvitaions`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};
export const UpdateTask = async (
  id,
  title,
  description,
  image,
  price,
  deadline
) => {
  var data = {
    id: id,
    title: title,
    description: description,
    image: image,
    price: price,
    period: deadline,
  };
  return await API.put(`/upt`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return Promise.reject(e);
    });
};
