import React from "react";
import "./JobListing.css";

const JobListing = ({ title, description, price, time, image }) => (
  <div className="job__listing">
    <div className="job__listing__image">
      <img
        src={image}
        alt="job listing"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src =
            "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
        }}
      />
    </div>
    <div className="job__listing__content">
      <div className="job__listing__content__text">
        <div className="job__listing__content__text__title">
          <h2>{title}</h2>
        </div>
        <div className="job__listing__content__descripttion">
          <p>{description}</p>
        </div>
      </div>
      <div className="job__listing__content__tags">
        <div className="tag__item">
          <p className="tag">{time}</p>
        </div>
        <div className="tag__item">
          <p className="tag">${price}</p>
        </div>
      </div>
    </div>
  </div>
);
export default JobListing;
