import React, { useContext, useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./UserDetails.css";
import {
  GetAllJoinRequests,
  GetUserProfile,
  GetUserProfileImage,
} from "../../store/services/user";
import {
  GetAllWorkerTasks,
  GetAllOwnerTasks,
  GetUserInvitationsTasks,
} from "../../store/services/tasks";
import AuthContext from "../../context/AuthProvider";
import getUserProfileDetails from "../../helpers/userProfile";
import getTasksList from "../../helpers/tasksList";
import checkTaskOverdue from "../../helpers/taskHelper";

import {
  USER_PROFILE_STATUS_CREATED,
  USER_TYPE_ADMIN,
  USER_TYPE_BUYER,
  USER_TYPE_SELLER,
} from "../../store/constants/users";

const Userdetail = () => {
  const authContext = useContext(AuthContext);
  const [tasksList, setTasksList] = useState([]);
  const [invitationsList, setInvitationsList] = useState([]);
  const [joinRequests, setJoinRequests] = useState(0);
  let newJobs = 0;
  let deliveredJobs = 0;
  let openJobs = 0;
  let closedJobs = 0;
  let overdueJobs = 0;
  const [userProfile, setUserProfile] = useState({
    user_profile_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    country: "",
    accountType: "",
    imageChanged: false,
    profile_image: "",
  });
  useEffect(() => {
    const fetchData1 = async () => {
      // document.getElementById('loaing').classList.remove("hidden__input");
      try {
        const profile = await GetUserProfile(
          authContext?.auth?.User?.attributes?.email
        );
        const userprofileDetails = getUserProfileDetails(profile);
        setUserProfile(userprofileDetails);
      } catch (error) {}
    };

    fetchData1();
  }, [setUserProfile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          localStorage.getItem("profile-status") === USER_PROFILE_STATUS_CREATED
        ) {
          if (authContext?.auth?.UserType === USER_TYPE_BUYER) {
            await GetAllOwnerTasks(
              authContext?.auth?.User?.attributes?.email
            ).then((tasks) => {
              const taskreaponce = getTasksList(tasks);
              setTasksList(taskreaponce);
            });
          } else if (authContext?.auth?.UserType === USER_TYPE_SELLER) {
            await GetAllWorkerTasks(
              authContext?.auth?.User?.attributes?.email
            ).then((tasks) => {
              const taskreaponce = getTasksList(tasks);
              setTasksList(taskreaponce);
            });
            await GetUserInvitationsTasks(
              authContext?.auth?.User?.attributes?.email
            ).then((tasks) => {
              const invitations = getTasksList(tasks);
              localStorage.setItem("inv", invitations.length);
              setInvitationsList(invitations);
            });
          } else if (authContext?.auth?.UserType === USER_TYPE_ADMIN) {
            const ls = await GetAllJoinRequests("new").then((usersResponse) => {
              let users = usersResponse?.data?.Items;
              setJoinRequests(users.length);
            });
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData("new");
  }, [setTasksList, setInvitationsList]);

  for (let i = 0; i < tasksList.length; i++) {
    if (tasksList[i].taskState === "new") {
      newJobs++;
    }
    if (tasksList[i].taskState === "delivered") {
      deliveredJobs++;
    }
    if (
      tasksList[i].taskState === "open" &&
      !checkTaskOverdue(tasksList[i].period)
    ) {
      openJobs++;
    }
    if (tasksList[i].taskState === "closed") {
      closedJobs++;
    }
    if (
      tasksList[i].taskState === "open" &&
      checkTaskOverdue(tasksList[i].period)
    ) {
      overdueJobs++;
    }
  }

  return (
    <div className="userdetail">
      <div className="userinfo">
        <Container>
          <Row>
            <Col xs lg="3">
              <img
                width="60px"
                height="60px"
                alt="userimage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
                }}
                src={GetUserProfileImage(
                  authContext?.auth?.User?.attributes?.sub
                )}
              />
            </Col>
            <Col xs lg="6">
              <h5 style={{ marginTop: 16 }}>{userProfile.first_name}</h5>
            </Col>
          </Row>
          <br />
          <div className="line"></div>
          {authContext?.auth?.UserType === USER_TYPE_ADMIN ? (
            <>
              <Row>
                <Col xs lg="10">
                  Join Requests
                </Col>
                <Col xs lg="2">
                  {joinRequests}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col xs lg="10">
                  New Jobs
                </Col>
                <Col xs lg="2">
                  {newJobs}
                </Col>
              </Row>
              <Row>
                <Col xs lg="10">
                  Delivered Jobs
                </Col>
                <Col xs lg="2">
                  {deliveredJobs}
                </Col>
              </Row>
              <Row>
                <Col xs lg="10">
                  Open Jobs
                </Col>
                <Col xs lg="2">
                  {openJobs}
                </Col>
              </Row>
              <Row>
                <Col xs lg="10">
                  Completed Jobs
                </Col>
                <Col xs lg="2">
                  {closedJobs}
                </Col>
              </Row>
              <Row>
                <Col xs lg="10">
                  Overdue Jobs
                </Col>
                <Col xs lg="2">
                  {overdueJobs}
                </Col>
              </Row>
            </>
          )}

          {authContext?.auth?.UserType === USER_TYPE_SELLER && (
            <Row>
              <Col xs lg="10">
                Invitations
              </Col>
              <Col xs lg="2">
                {authContext?.auth?.invitations.length}
              </Col>
            </Row>
          )}

          <div className="line"></div>
        </Container>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
export default Userdetail;
