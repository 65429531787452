import JobsList from "../../containers/jobsList/jobsList.js";
import Userdetail from "../../components/UserDetails/userdetail.js";
import "./Jobs.css";
import { useParams } from "react-router-dom";
import UserJobs from "../../containers/userJobs/userJobs.js";

export const Jobs = () => {
  let { status } = useParams();
  return (
    <div className="jobs__main">
      <div className="jobs__content">
        <div className="jobs__userdetails">
          <Userdetail />
        </div>
        <div className="jobs__jobsList">
          {status === "new" && <JobsList status={status} />}
          {status === "invitations" && <JobsList status={status} />}
          {status === "user-jobs" && <UserJobs />}
        </div>
      </div>
    </div>
  );
};
// export default Jobs;
