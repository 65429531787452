import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Container, Form, Alert, Button } from "react-bootstrap";
import {
  faPhone,
  faFax,
  faMapMarker,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactUs.css";
import config from "../../config.json";
import { AlertDialog } from "../../components/dialog/alertDialog.js";
import Loading from "../../components/loading/loading.js";
import { sesTest } from "../../store/services/SES";
function ContactUs() {
  const [formErrors, setFormErrors] = useState();
  const [showError, setShowError] = useState(false);
  const [emailState, SetEmailState] = useState({
    full_name: "",
    email_address: "",
    subject: "",
    description: "",
  });
  const [dialogMassage, setDialogMassage] = useState({
    massageTitle: "Error",
    massageContent: "",
  });
  const [showDialog, setShowDialog] = useState(false);

  const onInputChange = (event) => {
    SetEmailState({
      ...emailState,
      ...{ [event.target.id]: event.target.value },
    });
    setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!emailState.full_name) {
      errors.full_name = "full name is required!";
      document.getElementById("full_name").classList.add("is-danger");
    }

    if (!emailState.email_address) {
      errors.email_address = "Email is required!";
      document.getElementById("email_address").classList.add("is-danger");
    }
    if (!emailRegex.test(emailState.email_address)) {
      errors.email_address = "This is not a valid email format!";
      document.getElementById("email_address").classList.add("is-danger");
    }
    if (!emailState.subject) {
      errors.subject = "Subject is required!";
      document.getElementById("subject").classList.add("is-danger");
    }
    if (!emailState.description) {
      errors.description = "Email Body is required!";
      document.getElementById("description").classList.add("is-danger");
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors)?.length === 0) {
      send();
    }
  };

  const send = () => {
    document.getElementById("loaing").classList.remove("hidden__input");
    sesTest(
      config.SES.SOURCE_EMAIL,
      emailState.email_address,
      emailState.description,
      emailState.full_name,
      emailState.subject
    )
      .then((val) => {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Info",
          massageContent: "Operation Completed Successfully",
        });
      })
      .catch((err) => {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Error",
          massageContent: err.message,
        });
      })
      .finally(() => {
        document.getElementById("loaing").classList.add("hidden__input");
      });
  };

  return (
    <div className="userProfileDetails">
      <div className="hidden__input " id="loaing">
        <Loading />
      </div>
      <Form onSubmit={handleSubmit}>
        <Container className="user__profile__container">
          <br />
          <br />
          <br />
          <Row style={{ textAlign: "center" }}>
            <Col md="3">
              <i>
                <FontAwesomeIcon icon={faPhone} className="contact__us__icon" />
              </i>
              <br />
              <span>64640411 962+</span>
            </Col>
            <Col md="3">
              {" "}
              <FontAwesomeIcon icon={faFax} className="contact__us__icon" />
              <br />
              <span>64640406 962+</span>
            </Col>
            <Col md="3">
              {" "}
              <FontAwesomeIcon
                icon={faMapMarker}
                className="contact__us__icon"
              />
              <br />
              <span>
                Zahran gate Building - 1rd –Ismail Abdo street. Al Swaifyeh,
                Amman
              </span>
            </Col>
            <Col md="3">
              {" "}
              <FontAwesomeIcon
                icon={faEnvelope}
                className="contact__us__icon"
              />
              <br />
              <span>info@arij.net</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="6">
              <div className="content">
                <Form.Control
                  id="full_name"
                  type="text"
                  placeholder="Full Name"
                  onChange={(e) => onInputChange(e)}
                />
                <p className="error__lable">{formErrors?.full_name}</p>
              </div>
            </Col>
            <Col lg="6">
              <div className="content">
                <Form.Control
                  id="email_address"
                  placeholder="Email Adress"
                  type="text"
                  onChange={(e) => onInputChange(e)}
                />
                <p className="error__lable">{formErrors?.email_address}</p>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control
                id="subject"
                placeholder="subject"
                onChange={(e) => onInputChange(e)}
              ></Form.Control>
              <p className="error__lable">{formErrors?.subject}</p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control
                id="description"
                as="textarea"
                rows={5}
                onChange={(e) => onInputChange(e)}
              />
              <p className="error__lable">{formErrors?.description}</p>
            </Col>
          </Row>
          <br />
          {!showError && (
            <Button
              className="createButton"
              size="nd"
              block="true"
              type="submit"
            >
              Send
            </Button>
          )}
        </Container>
      </Form>
      <AlertDialog
        show={showDialog}
        title={dialogMassage.massageTitle}
        content={dialogMassage.massageContent}
        agreeAction={"Ok"}
        disagreeAction={"cancel"}
        handleDisagree={() => setShowDialog(false)}
        handleAgree={() => setShowDialog(false)}
      ></AlertDialog>
    </div>
  );
}

export default ContactUs;
