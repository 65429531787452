import { faStoreAltSlash, faUserXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./EmptyListPlaceholder.css";
const EmptyListPlaceholder = ({ listType }) => {
  const [content, setContent] = useState({
    text: "No Jobs For Now",
    icon: faStoreAltSlash,
  });
  useEffect(() => {
    if (listType === "jobs") {
      setContent({
        text: "No Jobs For Now",
        icon: faStoreAltSlash,
      });
    } else if (listType === "join-requests") {
      setContent({
        text: "No Requests For Now",
        icon: faUserXmark,
      });
    }
  }, [setContent]);

  return (
    <div className="empty__list__placeholder">
      <h1>{content.text}</h1>

      <br />
      <FontAwesomeIcon icon={content.icon} size="6x" />
    </div>
  );
};
export default EmptyListPlaceholder;
