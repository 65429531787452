import React from "react";
import { Button } from "react-bootstrap";
import "./Welcome.css";

export const Welcome = () => {
  return (
    <div className="welcome">
      <h3>Welcome To AFCN</h3>
      <br />
      <p>You have successfully registered a new account.</p>
      <p>
        We will send you a verify email once your profile get approved. Please click on the confirmation link to
        verify your account.
      </p>

      <br />
      <a href="/signin">
        <Button variant="success-c">Sign In</Button>
      </a>
    </div>
  );
};
