import AWS from "aws-sdk";
import config from "../../config.json";

const S3_BUCKET = config.S3.S3_BUCKET;
const REGION = config.AWS.REGION;

AWS.config.update({
  accessKeyId: config.AWS.ACCESS_KEY_ID,
  secretAccessKey: config.AWS.SECRET_ACCESS_KEY,
});

const S3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
export const GetS3FilePath = (fileName) => {
  return (
    "https://" + S3_BUCKET + ".s3." + REGION + ".amazonaws.com/" + fileName
  );
};
export const UploadToS3 = async (file, filePath) => {
  try {
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: filePath,
    };

    S3.putObject(params).send((err) => {
      if (err) console.log(err);
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ListFilesFromS3 =  (filePath,onComplete) => {
  try {
    const params = {
      Bucket: S3_BUCKET,
      Delimiter: "",
      Prefix: filePath,
    };
   
    const res=S3.listObjectsV2(params,onComplete);

  
   
  } catch (error) {
    return Promise.reject(error);
  }
};
