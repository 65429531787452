import UserProfileDetails from "../../containers/userProfileDetails/userProfileDetails.js";
import "./UserProfile.css";

export const UserProfile = () => {
  return (
    <div className="main">
      <div className="user__profile__content">
        <div className="user__profile__userdetails">
          <UserProfileDetails />
        </div>
      </div>
    </div>
  );
};
