import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import {
  GetS3FilePath,
  ListFilesFromS3,
} from "../../store/services/uploadImageToS3";
import "./Slider.css";

const Slider = () => {
  const [sliderImages, setSliderImages] = useState([]);
  useEffect(() => {
    ListFilesFromS3("assets/slider/", (err, data) => {
      if (err) {
      } else {
        setSliderImages(data.Contents);
      }
    });
  }, [setSliderImages]);
  return (
    <div className="slider">
      <Carousel>
        <Carousel.Item>
          <Container className="d-block w-100 slider__content">
            <Row>
              <Col lg="6">
                <h1 className="about__us__header">About the project</h1>
                <p>
                  SA7 is a project by Arab Fact-Checkers Network (AFCN) & ARIJ,
                  with the support of the "Qarib" regional program implemented
                  by the French Media Development Agency (CFI) and funded by the
                  French Development Agency (AFD)
                </p>
                <a href="/join">
                  <button className="slider__button">Join</button>
                </a>
              </Col>
              <Col lg="6">
                <img
                  className="d-block w-100"
                  src={GetS3FilePath("assets/slider/Slider4.gif")}
                  alt="slider"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container className="d-block w-100 slider__content">
            <Row>
              <Col lg="6">
                <h1 className="about__us__header">About AFCN</h1>
                <p>
                  The Arab Fact-Checkers Network (AFCN) established in December
                  2020 and led by ARIJ, is a grassroots network that works
                  towards fostering transparent and impartial fact-checking in
                  the Arab region. This network is a product of the
                  mis/disinformation spikes amid the unprecedented times of
                  COVID-19 worldwide
                </p>
                <a href="/AboutUs">
                  <button className="slider__button">Read More</button>
                </a>
              </Col>
              <Col lg="6">
                <img
                  className="d-block w-100"
                  src={GetS3FilePath("assets/owners/afcn.png")}
                  alt="slider"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container className="d-block w-100 slider__content">
            <Row>
              <Col lg="6">
                <h1 className="about__us__header">About ARIJ</h1>
                <p>
                  ARIJ has long been on the frontline of investigative
                  journalism in the MENA region, providing theoretical and
                  hands-on training to journalists at different career stages.
                  Since 2006, it has trained 3500 journalists, editors and fact
                  checkers and supported over 650 quality investigations. In the
                  past five years, it won over 60 local, regional and
                  international awards
                </p>
                <a href="/AboutUs">
                  <button className="slider__button">Read More</button>
                </a>
              </Col>
              <Col lg="6">
                <img
                  className="d-block w-100"
                  src={GetS3FilePath("assets/owners/arij-black.png")}
                  alt="slider"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
export default Slider;
