import Userdetail from "../../components/UserDetails/userdetail.js";
import "./CreateUser.css";
import CreateUserInfo from "../../containers/CreateUserInfo/createUserInfo.js";
export const CreateUser = () => {
  return (
    <div className="create__user__main">
      <div className="create__user__content">
        <div className="create__user__userdetails">
          <Userdetail />
        </div>
        <div className="create__user__user__info__details">
          <CreateUserInfo/>
        </div>
      </div>
    </div>
  );
};
