import React, { useEffect, useState, useRef } from "react";
import Avatar from "./Avatar.js";
import ChatWindow from "./ChatWindow.js";
import "./Chat.css";

const Chat = (props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [visible, setVisible] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div ref={wrapperRef}>
      <ChatWindow
        visible={visible}
        taskId={props.taskId}
      />
      <Avatar
        onClick={() => {
          setVisible(!visible);
        }}
        style={{
          position: "fixed",
          bottom: "100px",
          right: "24px",
        }}
      />
    </div>
  );
};

export default Chat;
