const checkRegex = (type) => {
  if (type === "text") {
    return /^[a-zA-Z ]*$/;
  } else if (type === "number") {
    return /^[0-9\b]+$/;
  }else {
    return "";
  }
};

export default checkRegex;
