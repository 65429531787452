import {  faStoreAltSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import "./NoJobsPlaceholder.css"
 const NoJobsPlaceholder = () => {
  return (
    <div className='no__jobs__placeholder'>
        <h1>No Jobs For Now</h1>
        <br/>
        <FontAwesomeIcon icon={faStoreAltSlash} size="6x"/>
    </div>
  )
}
export default NoJobsPlaceholder;
