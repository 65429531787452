import React from "react";
import "./AboutUsPartnerInfo.css";
const AboutUsPartnerInfo = ({ title, content, image, link }) => {
  return (
    <div>
      <h1 className="about__us__header">{title}</h1>
      <div className="about__us__about__content">
        <div className="about__us__about__img">
          <a href={link} target="_blank">
            <img src={image} alt={title} />
          </a>
        </div>

        <p className="about__us__about__text">{content}</p>
      </div>
    </div>
  );
};
export default AboutUsPartnerInfo;
