import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import EmptyListPlaceholder from "../../components/emptyListPlaceholder/emptyListPlaceholder";
import JoinRequestListing from "../../components/joinRequestListing/joinRequestListing";
import Loading from "../../components/loading/loading";
import {
  DeleteJoinRequest,
  GetAllJoinRequests,
} from "../../store/services/user";
import { Auth } from "aws-amplify";
import "./JoinRequestsList.css";
import { AlertDialog } from "../../components/dialog/alertDialog";
import { decrypt } from "../../helpers/encrypt";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
const JoinRequestsList = () => {
  const [users, setUsers] = useState([]);
  const [dialogMassage, setDialogMassage] = useState({
    massageTitle: "Error",
    massageContent: "",
  });
  const [showDialog, setShowDialog] = useState(false);
  const handleDialogAgree = async () => {
    setShowDialog(false);
  };
  const fetchData = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      await GetAllJoinRequests("new").then((usersResponse) => {
        let users = usersResponse?.data?.Items;
        setUsers(users);
      });
    } catch (error) {
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error,
      });
    } finally {
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  useEffect(() => {
    fetchData();
  }, [setUsers]);

  const handleSubmit = async (user, userAction) => {
    document.getElementById("loaing").classList.remove("hidden__input");
    try {
      const username = user.username;
      const password = decrypt(user.userString);
      const email = user.email;
      const accountType = user.usertype;
      if (userAction === "Accept") {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email: email,
            "custom:AcountType": accountType,
          },
        });
      }
      await DeleteJoinRequest(email);
      fetchData();
    } catch (error) {
      let err = null;
      !error.message ? (err = error) : (err = error.message);
      console.log("error", error.message);
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: err,
      });
    } finally {
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  return (
    <div className="join__requests__list">
      <div className="hidden__input " id="loaing">
        <Loading />
      </div>
      <div className="join__requests__list__content">
        <div className="join__requests__header">
          <a href="/creatuser">
            <Button variant="success-c" size="nd" block="true">
              Create Admin User
            </Button>
          </a>
        </div>
        {users.length > 0 ? (
          users.map((user, index) => (
            <Link
              className="job__link"
              to="/join-request-info"
              state={{ user: user }}
              key={user.id + " " + index}
            >
              <JoinRequestListing
                key={index}
                user={user}
                action={"Accept"}
                onSubmit={handleSubmit}
              />
            </Link>
          ))
        ) : (
          <EmptyListPlaceholder listType={"join-requests"} />
        )}
      </div>
      <AlertDialog
        show={showDialog}
        title={dialogMassage.massageTitle}
        content={dialogMassage.massageContent}
        agreeAction={"Ok"}
        disagreeAction={"cancel"}
        handleDisagree={() => setShowDialog(false)}
        handleAgree={() => handleDialogAgree()}
      ></AlertDialog>
    </div>
  );
};

export default JoinRequestsList;
