import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JobListing from "../../components/jobListing/joblisting";
import Loading from "../../components/loading/loading";
import NoJobsPlaceholder from "../../components/noJobsPlaceholder/noJobsPlaceholder";
import AuthContext from "../../context/AuthProvider";
import { getTaskPrice } from "../../helpers/taskFromList";
import getTasksList from "../../helpers/tasksList";
import { USER_TYPE_BUYER } from "../../store/constants";
import {
  GetAllTasks,
  GetTaskProfileImage,
  GetUserInvitationsTasks,
} from "../../store/services/tasks";
import "./JobsList.css";

const JobsList = (props) => {
  const [tasksList, setTasksList] = useState([]);
  const [navigateTo, setNavigateTo] = useState();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      document.getElementById("loaing").classList.remove("hidden__input");
      try {
        if (props.status === "new") {
          await GetAllTasks(props.status).then((tasks) => {
            const tasksList = getTasksList(tasks);
            setTasksList(tasksList);
          });
        } else if (props.status === "invitations") {
          await GetUserInvitationsTasks(
            authContext?.auth?.User?.attributes?.email
          ).then((tasks) => {
            const tasksList = getTasksList(tasks);
            setTasksList(tasks?.data);
          });
        }
      } catch (error) {
        // setShowError(true);
        // let err = null;
        // !error.message ? (err = { message: error }) : (err = error);
        // setExceptionError(error.message);
      } finally {
        document.getElementById("loaing").classList.add("hidden__input");
      }
    };
    fetchData();

    if (authContext?.auth?.UserType === USER_TYPE_BUYER) {
      setNavigateTo("/creat-job/view");
    } else {
      setNavigateTo("/creat-job/apply");
    }
  }, [setTasksList]);

  return (
    <div className="jobs__list">
      <div className="hidden__input " id="loaing">
        <Loading />
      </div>
      <div className="jobs__list__content">
        {tasksList.length > 0 ? (
          tasksList.map((item, index) => (
            <Link
              className="job__link"
              to={navigateTo}
              state={{ job: item }}
              key={item.id + " " + index}
            >
              <JobListing
                title={item.title}
                description={item.description}
                price={getTaskPrice(item.price)}
                time={item.period}
                image={item.image}
              />
            </Link>
          ))
        ) : (
          <NoJobsPlaceholder />
        )}
      </div>
    </div>
  );
};

export default JobsList;
