import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

const ProtectedRoute = ({ children }) => {
    const authContext = useContext(AuthContext);
  return authContext?.auth?.AuthStatus ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;
