import JobDetails from "../../containers/JobDetails/jobdetails.js";
import Userdetail from "../../components/UserDetails/userdetail.js";
import "./CreateJob.css"
import { useLocation, useParams } from "react-router-dom";
import { TASK_ACTION_CREATE } from "../../store/constants/taskActions.js";
export const Createjob = () => {
  let { action } = useParams();
  const location = useLocation()
  let job={
    title: "",
    description: "",
    price: "",
    deadline: "",
    image: "",
    attachments: [""],
  };
  if(action !==TASK_ACTION_CREATE){
    job=location.state.job;
  }
  return (
    <div className="create__job__main">
      <div className="createjob__content">
        <div className="createjob__userdetails"><Userdetail /></div>
        <div className="createjob__jobdetails"><JobDetails action={action}  job={job}/></div>
      </div>
    </div>
  );
};
