import CryptoJS from "crypto-js";

var key = CryptoJS.enc.Utf8.parse(
  "07718d79@923a#4ecf-84b4-c2DB3fF641a320221030T81352652J75"
); //randomly generated
var iv = CryptoJS.enc.Utf8.parse(
  "17ac8673-d023!4a19%ab83$09f0820a1542202G10300932T26759H3"
); //randomly generated

export const encrypt = (data) => {
  var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
    KeySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encryptedData.toString();
};

export const decrypt = (data) => {
  var decryptedData = CryptoJS.AES.decrypt(data, key, {
    KeySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decryptedData);
};
