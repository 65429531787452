import React, { useState } from "react";
import "./CreateUserInfo.css";
import Loading from "../../components/loading/loading";
import { AlertDialog } from "../../components/dialog/alertDialog";
import { Button, Form } from "react-bootstrap";
import { USER_TYPE_ADMIN } from "../../store/constants";
import { Auth } from "aws-amplify";
import { AddUserProfile } from "../../store/services/user";

const CreateUserInfo = () => {
  const [dialogMassage, setDialogMassage] = useState({
    massageTitle: "Error",
    massageContent: "",
  });
  const [showDialog, setShowDialog] = useState(false);

  const [status, setStatus] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    document.getElementById("loaing").classList.remove("hidden__input");
    try{
      const username = status.username;
      const password = status.password;
      const email = status.email;
      const accountType = USER_TYPE_ADMIN;
      await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          "custom:AcountType": accountType,
        },
      });
      const userProfile = {
        first_name: "",
        last_name: "",
        email: email,
        username: username,
        accountType: accountType,
        phone_number: "",
        cognitoId:"",
        country: "",
        facebook: "",
        linkedin: "",
        gender: "",
        experience: "",
        work: "",
        organization: "",
        english: "",
        arabic: "",
        topic: "",
        topicOther: "",
        linkone: "",
        linktwo: "",
        linkthree: "",
        organizationName: "",
        organizationWebsite: "",
        organizationType: "",
        organizationTypeOther: "",

      };
      await AddUserProfile(userProfile);
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Info",
        massageContent: "Operation Completed Successfully",
      });
    }catch (error) {
      let err = null;
      !error.message ? (err = error) : (err = error.message);
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: err,
      });
    } finally {
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };

  const onInputChange = (event) => {
    setStatus({ ...status, ...{ [event.target.id]: event.target.value } });
    setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };
  return (
    <div className="create__user">
      <div className="hidden__input" id="loaing">
        <Loading />
      </div>
      <div className="create__user__form">
        <Form onSubmit={handleSubmit}>
          <h3>Create Admin User</h3>
          <br></br>
          <Form.Group
            className="create__user__info__form__group"
            controlId="email"
          >
            Email
            <Form.Control
              type="text"
              placeholder="Email"
              value={status.email}
              onChange={(e) => onInputChange(e)}
              // required={true}
            />
          </Form.Group>
          <p className="error__lable">{formErrors?.email}</p>
          <Form.Group
            className="create__user__info__form__group"
            controlId="username"
          >
            User Name
            <Form.Control
              type="text"
              placeholder="User Name"
              value={status.username}
              onChange={(e) => onInputChange(e)}
              // required={true}
            />
          </Form.Group>
          <p className="error__lable">{formErrors?.username}</p>
          <Form.Group
            className="create__user__info__form__group"
            controlId="password"
          >
            Password
            <Form.Control
              type="password"
              placeholder="Password"
              value={status.password}
              onChange={(e) => onInputChange(e)}
              // required={true}
            />
          </Form.Group>
          <p className="error__lable">{formErrors?.password}</p>

          <Button variant="success-c" size="nd" block="true" type="submit">
            Create
          </Button>
        </Form>
        <AlertDialog
          show={showDialog}
          title={dialogMassage.massageTitle}
          content={dialogMassage.massageContent}
          agreeAction={"Ok"}
          disagreeAction={"cancel"}
          handleDisagree={() => setShowDialog(false)}
          handleAgree={() => setShowDialog(false)}
        ></AlertDialog>
      </div>
    </div>
  );
};

export default CreateUserInfo;
