export const getTaskFromResponseListItem = (responseTask) => {
  let task = {};
  task.applicants = responseTask?.applicants?.SS || [];
  task.attachments = responseTask?.attachments?.SS || [];
  task.genres = responseTask?.genres?.SS || [];
  task.description = responseTask?.description || "";
  task.id = responseTask?.id || "";
  task.image = responseTask?.image || "";
  task.deadline = responseTask?.period || "";
  task.owner = responseTask?.owner || "";
  task.payment = responseTask?.payment || {};
  task.price = getTaskPrice(responseTask?.price) || 0;
  task.title = responseTask?.title || "";
  task.worker = responseTask?.worker || "";
  task.taskState = responseTask?.taskState || "";
  task.taskComment = responseTask?.taskComment["py/set"] || [];
  task.taskConclusions = responseTask?.taskConclusions["py/set"] || [];
  return task;
};
export const getTaskPrice = (responsePrice) => {
  const price = responsePrice["py/reduce"][1]["py/tuple"][0];
  return price;
};
export const getTransactionId = (responseTransactionId) => {
  const transactionId = responseTransactionId["py/reduce"][1]["py/tuple"][0];
  return transactionId;
};
