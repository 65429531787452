import API from "./API";

export const GetChatById = async (id) => {
  return await API.get(`/getchatbyid`, {
    params: {
      taskId: id,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const AddChat = async (id) => {
  var data = {
    taskId: id,
    chatData: [],
  };
  return await API.post(`/addchat`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const updateChatInfo = async (id, contant, sender, reciver, date) => {
  var data = {
    chatId: id,
    newData: {
      contant: contant,
      sender: sender,
      recivser: reciver,
      dateTime: date,
    },
  };
  return await API.put(`/updatechat`, data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
