import JoinRequestsList from "../../containers/joinRequestsList/joinRequestsList";
import "./JoinRequests.css";
import Userdetail from "../../components/UserDetails/userdetail.js";
export const JoinRequests = () => {
  return (
    <div className="join__requests__main">
      <div className="join__requests__content">
        <div className="join__requests__userdetails">
          <Userdetail />
        </div>
        <div className="join__requests__jobsList">
          <JoinRequestsList />
        </div>
      </div>
    </div>
  );
};
