import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/home.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JoinForm from "./pages/join/join.js";
import SigninForm from "./pages/signIn/signin.js";
import { Jobs } from "./pages/Jobs/jobs.js";
import { Createjob } from "./pages/CreateJob/creatjob.js";
import { UserProfile } from "./pages/userProfile/userProfile.js";
import { Welcome } from "./pages/welcome/welcome.js";
import { Header } from "./components";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import AuthContext from "./context/AuthProvider";
import { useContext } from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import ChangePassword from "./pages/changePassword/changePassword";
import ForgotPasswordVerification from "./pages/forgotPasswordVerification/forgotPasswordVerification";
import ContactUs from "./containers/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import AboutUs from "./containers/aboutUs/aboutUs";
import { JoinRequests } from "./pages/JoinRequests/joinRequests";
import { JoinRequestInfo } from "./pages/JoinRequestInfo/joinRequestInfo";
import { CreateUser } from "./pages/CreateUser/createUser";

function App() {
  const authContext = useContext(AuthContext);

  return (
    <div className="bgcolor">
      <Router>
        <Header />
        {authContext?.auth?.isAuthReady && (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/join" element={<JoinForm />} />
            <Route exact path="/signin" element={<SigninForm />} />
            <Route
              path="/join-requests"
              element={
                <ProtectedRoute>
                  <JoinRequests />
                </ProtectedRoute>
              }
            />
            <Route
              path="/jobs/:status"
              element={
                <ProtectedRoute>
                  <Jobs />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/creat-job/:action"
              element={
                <ProtectedRoute>
                  <Createjob />
                </ProtectedRoute>
              }
            />
             <Route
              exact
              path="/join-request-info"
              element={
                <ProtectedRoute>
                  <JoinRequestInfo />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/user-profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
             <Route
              exact
              path="/creatuser"
              element={
                <ProtectedRoute>
                  <CreateUser />
                </ProtectedRoute>
              }
            />
            <Route exact path="/welcome" element={<Welcome />} />
            <Route
              exact
              path="/change-password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/forgot-password-verification"
              element={<ForgotPasswordVerification />}
            />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/ContactUs" element={<ContactUs />} />
            <Route exact path="/AboutUs" element={<AboutUs />} />
          </Routes>
        )}
      </Router>
      <Footer />
    </div>
  );
}

export default App;
