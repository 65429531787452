import { Male } from "@mui/icons-material";
import {
  LANGUAGE_PROFICIENCY_ADVANCED,
  LANGUAGE_PROFICIENCY_BEGINNER,
  LANGUAGE_PROFICIENCY_INTERMEDIATE,
  LANGUAGE_PROFICIENCY_UPPER_INTERMEDIATE,
  ORGANIZATION_TYPE_FACT_CHECKING_ORGANIZATION,
  ORGANIZATION_TYPE_MEDIA_ORGANIZATION,
  ORGANIZATION_TYPE_NGO,
  ORGANIZATION_TYPE_OTHER,
  ORGANIZATION_TYPE_UNIVERSITY,
  PREFERED_TOPIC_ENTERTAINMENT,
  PREFERED_TOPIC_HEALTH,
  PREFERED_TOPIC_OTHER,
  PREFERED_TOPIC_POLITICS,
  PREFERED_TOPIC_SCIENCE,
  PREFERED_TOPIC_SOCIAL,
  USER_TYPE_ADMIN,
  USER_TYPE_BUYER,
  USER_TYPE_SELLER,
} from "../store/constants";

const getUserProfileDetails = (response) => {
  const item = response?.data?.Item;
  const userProfileDetails = {
    first_name: item?.first_name?.S || "",
    last_name: item?.last_name?.S || "",
    phone_number: item?.phone_number?.S || "",
    email: item?.email?.S || "",
    country: item?.country?.S || "",
    role: item?.role?.S || "",
    image: "",
    profile_image: "",
    username: item?.username?.S || "",
    subscriptions: item?.subscriptions?.SS || "",
    facebook: item?.facebook?.S || "",
    linkedin: item?.linkedin?.S || "",
    gender: item?.gender?.S || "",
    experience: item?.experience?.S || "",
    work: item?.work?.S || "",
    organization: item?.organization?.S || "",
    english: item?.english?.S || "",
    arabic: item?.arabic?.S || "",
    topic: item?.topic?.S || "",
    topicOther: item?.topicOther?.S || "",
    linkone: item?.linkone?.S || "",
    linktwo: item?.linktwo?.S || "",
    linkthree: item?.linkthree?.S || "",
    organizationName: item?.organizationName?.S || "",
    organizationWebsite: item?.organizationWebsite?.S || "",
    organizationType: item?.organizationType?.S || "",
    organizationTypeOther: item?.organizationTypeOther?.S || "",
  };
  return userProfileDetails;
};
export const getGender = (genderValue) => {
  if (genderValue === Male) {
    return "Male";
  } else {
    return "Female";
  }
};
export const getPreferedTopic = (preferedTopicValue) => {
  if (preferedTopicValue === PREFERED_TOPIC_ENTERTAINMENT) {
    return "Entertainment";
  } else if (preferedTopicValue === PREFERED_TOPIC_HEALTH) {
    return "Health";
  } else if (preferedTopicValue === PREFERED_TOPIC_OTHER) {
    return "Other";
  } else if (preferedTopicValue === PREFERED_TOPIC_POLITICS) {
    return "Politics";
  } else if (preferedTopicValue === PREFERED_TOPIC_SCIENCE) {
    return "Science";
  } else if (preferedTopicValue === PREFERED_TOPIC_SOCIAL) {
    return "Social";
  } else {
    return preferedTopicValue;
  }
};
export const getLanguageProficiency = (languageProficiencyValue) => {
  if (languageProficiencyValue === LANGUAGE_PROFICIENCY_BEGINNER) {
    return "Beginner";
  } else if (languageProficiencyValue === LANGUAGE_PROFICIENCY_INTERMEDIATE) {
    return "Intermediate";
  } else if (
    languageProficiencyValue === LANGUAGE_PROFICIENCY_UPPER_INTERMEDIATE
  ) {
    return "Upper intermediate";
  } else if (languageProficiencyValue === LANGUAGE_PROFICIENCY_ADVANCED) {
    return "Advanced";
  } else {
    return languageProficiencyValue;
  }
};

export const getUserTypeText = (userType) => {
  let userTypeText = "";
  if (userType === USER_TYPE_BUYER) {
    userTypeText =
      "Fact-checking Organization - Media Organization - University or College - NGO - Others";
  } else if (userType === USER_TYPE_SELLER) {
    userTypeText = "Fact-checker";
  } else if (userType === USER_TYPE_ADMIN) {
    userTypeText = "Admin";
  }
  return userTypeText;
};
export const getUserOrganizationType = (organizationType) => {
  let userOrganizationType = "";
  if (organizationType === ORGANIZATION_TYPE_MEDIA_ORGANIZATION) {
    userOrganizationType = "Media Organization";
  } else if (
    organizationType === ORGANIZATION_TYPE_FACT_CHECKING_ORGANIZATION
  ) {
    userOrganizationType = "Fact-checking Organization";
  } else if (organizationType === ORGANIZATION_TYPE_UNIVERSITY) {
    userOrganizationType = "University or College";
  } else if (organizationType === ORGANIZATION_TYPE_NGO) {
    userOrganizationType = "NGO";
  } else if (organizationType === ORGANIZATION_TYPE_OTHER) {
    userOrganizationType = "Others";
  }
  return userOrganizationType;
};
export default getUserProfileDetails;
