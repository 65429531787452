const checkFileTypeSupported = (fileType) => {
    if (fileType.includes("image/")) {
      return true;
    }
    if (fileType.includes("video/")) {
      return true;
    }
    if (fileType==="application/msword") {
      return true;
    }
    if (fileType==="application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return true;
    }
    if (fileType==="text/csv") {
      return true;
    }
    if (fileType==="application/pdf") {
      return true;
    }
    if (fileType==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return true;
    }
    if (fileType===" application/vnd.ms-excel") {
      return true;
    }
    if (fileType==="text/plain") {
      return true;
    }
  }

  export default checkFileTypeSupported;