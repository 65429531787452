import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./ForgotPasswordVerification.css";
import { Auth } from "aws-amplify";
import Validate from "../../components/utility/FormValidation.js";
import { useNavigate } from "react-router-dom";

const ForgotPasswordVerification = () => {
  const clearErrorState = () => {
    setStatus({
      ...status,
      ...{
        errors: {
          cognito: null,
          blankfield: false,
          passwordmatch: false,
        },
      },
    });
  };
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!status.code) {
      errors.code = "code is required!";
      document.getElementById("code").classList.add("is-danger");
    }
    if (!status.password) {
      errors.password = "Password is required!";
      document.getElementById("password").classList.add("is-danger");
    }
    if (!status.confirmpassword) {
      errors.confirmpassword = "confirmpassword is required!";
      document.getElementById("confirmpassword").classList.add("is-danger");
    }
    if (!status.username) {
      errors.username = "username is required!";
      document.getElementById("username").classList.add("is-danger");
    }
    return errors;
  };

  const [formErrors, setFormErrors] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    clearErrorState();
    if (status.password !== status.confirmpassword) {
    }
    const validationErrors=validate();
    setFormErrors(validationErrors)
    // const error = Validate(event, status);
    // if (error) {
    //   setStatus({
    //     ...status,
    //     ...{
    //       errors: { ...status.errors, ...error },
    //     },
    //   });
    // }

    // AWS Cognito integration here
    try {
      const signUpResponse = await Auth.forgotPasswordSubmit(
        status.username,
        status.code,
        status.password
      );
      navigate("/signin");
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setStatus({
        ...status,
        ...{
          errors: {
            ...status.errors,
            cognito: err,
          },
        },
      });
    }
  };

  const [status, setStatus] = useState({
    code: "",
    username: "",
    password: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false,
    },
  });

  const onInputChange = (event) => {
    setStatus({ ...status, ...{ [event.target.id]: event.target.value } });
    // document.getElementById(event.target.id).classList.remove("is-danger");
    setFormErrors({ ...formErrors, ...{ [event.target.id]: '' } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };
  return (
    <div className="forgotPasswordVerificationForm">
      <Form onSubmit={handleSubmit}>
        <h1>Set new password</h1>
        <p>
          Please enter the verification code sent to your email address below,
          your Username and a new password.
        </p>
        <br></br>
        <Form.Group
          className="forgotPasswordVerificationGroup"
          controlId="code"
        >
          <Form.Control
            placeholder="Enter verification code"
            value={status.code}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.code}</p>

        <Form.Group
          className="forgotPasswordVerificationGroup"
          controlId="username"
        >
          <Form.Control
            type="text"
            placeholder="User Name"
            value={status.username}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.username}</p>
        <Form.Group
          className="forgotPasswordVerificationGroup"
          controlId="password"
        >
          <Form.Control
            type="password"
            placeholder="New Password"
            value={status.password}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.password}</p>
        <Form.Group
          className="forgotPasswordVerificationGroup"
          controlId="confirmpassword"
        >
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={status.confirmpassword}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.confirmpassword}</p>
        <Form.Group className="joinFormGroup" controlId="formBasicName">
          <Button variant="success-c" size="nd" block="true" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};
export default ForgotPasswordVerification;
