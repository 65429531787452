export const GENDER_MALE = "1001";
export const GENDER_FEMALE = "1002";
export const YES = "2001";
export const NO = "2002";
export const LANGUAGE_PROFICIENCY_BEGINNER = "5001";
export const LANGUAGE_PROFICIENCY_INTERMEDIATE = "5002";
export const LANGUAGE_PROFICIENCY_UPPER_INTERMEDIATE = "5003";
export const LANGUAGE_PROFICIENCY_ADVANCED = "5004";
export const PREFERED_TOPIC_POLITICS = "6001";
export const PREFERED_TOPIC_SOCIAL = "6002";
export const PREFERED_TOPIC_SCIENCE = "6003";
export const PREFERED_TOPIC_HEALTH = "6004";
export const PREFERED_TOPIC_ENTERTAINMENT = "6005";
export const PREFERED_TOPIC_OTHER = "6006";
export const ORGANIZATION_TYPE_MEDIA_ORGANIZATION = "7001";
export const ORGANIZATION_TYPE_FACT_CHECKING_ORGANIZATION = "7002";
export const ORGANIZATION_TYPE_UNIVERSITY = "7003";
export const ORGANIZATION_TYPE_NGO = "7004";
export const ORGANIZATION_TYPE_OTHER = "7005";
