import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import './loading.css'


const Loading=()=>{

    return(
        <div className="load">
        <ReactLoading
        type={"cylon"}
        color={"#03fc4e"}
        height={500}
        width={500}
        className="reactLoad"
      />
      </div>
    );
}


export default Loading;