const getTasksList = (response) => {
  let taskList;
  if (response?.data?.Items) {
    taskList = response?.data?.Items;
  } else {
    taskList = response?.data;
  }

  return taskList;
};
export default getTasksList;
