import axios from "axios";
// import axiosRetry from 'axios-retry';

const API = axios.create({
  baseURL: "https://xtjpylqv1k.execute-api.eu-west-1.amazonaws.com/dev",
  responseType: "json",
  // headers: {
  //   "Content-Type": "application/json",
  //   // "Access-Control-Allow-Origin": "*",
  //   // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  //   // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  // },
  validateStatus: (status) => {
    return status >= 200 && status < 400; // default
  },
});
// axiosRetry(API, { retries: 3 });

// redirect to login if 401
// API.interceptors.response.use(null, (err) => {
//   if (err.response.status === 401) {
//     sessionStorage.removeItem("token");
//     sessionStorage.removeItem("user");

//     window.location.pathname = "/signin";
//   }

//   return Promise.reject(err);
// });

// API.interceptors.request.use(
//   config => {
//     const token = sessionStorage.getItem('token');

//     if (token != null) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   }
// );

export default API;
