import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Container, Form, Alert, Button } from "react-bootstrap";
import "./UserProfileDetails.css";
import AuthContext from "../../context/AuthProvider";
import getUserProfileDetails, {
  getGender,
  getLanguageProficiency,
  getPreferedTopic,
  getUserOrganizationType,
  getUserTypeText,
} from "../../helpers/userProfile";
import {
  GetUserProfile,
  AddUserProfile,
  GetUserProfileImage,
} from "../../store/services/user";
import { RoundImagePreview } from "../../components/roundImagePreview/roundImagePreview.js";
import checkRegex from "../../utl/regex.js";
import Loading from "../../components/loading/loading";
import { UploadToS3 } from "../../store/services/uploadImageToS3";
import { getCountriesList } from "../../utl/countries";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  ORGANIZATION_TYPE_OTHER,
  PREFERED_TOPIC_OTHER,
  USER_PROFILE_STATUS_CREATED,
  USER_TYPE_BUYER,
  USER_TYPE_SELLER,
  YES,
} from "../../store/constants";

const UserProfileDetails = () => {
  const authContext = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState();
  const [exceptionError, setExceptionError] = useState("");
  const [massageTitle, setMassageTitle] = useState("Error");
  const [showError, setShowError] = useState(false);
  const [showOrganization, setShowOrganization] = useState("none");
  const [showOrganizationTypeOther, setShowOrganizationTypeOther] =
    useState("none");
  const [showTopicOther, setShowTopicOther] = useState("none");
  const [showWorkerExtraData, setShowWorkerExtraData] = useState("none");
  const [showOwnerExtraData, setShowOwnerExtraData] = useState("none");
  const [userProfile, setUserProfile] = useState({
    user_profile_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    country: "",
    accountType: "",
    imageChanged: false,
    profile_image: "",
    facebook: "",
    linkedin: "",
    gender: "",
    experience: "",
    work: "",
    organization: "",
    english: "",
    arabic: "",
    topic: "",
    topicOther: "",
    linkone: "",
    linktwo: "",
    linkthree: "",
    organizationName: "",
    organizationWebsite: "",
    organizationType: "",
    organizationTypeOther: "",
  });

  const validate = () => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!userProfile.first_name) {
      errors.first_name = "First Name is required!";
      document.getElementById("first_name").classList.add("is-danger");
    }

    if (!userProfile.last_name) {
      errors.last_name = "Last Name is required!";
      document.getElementById("last_name").classList.add("is-danger");
    }
    if (!userProfile.phone_number) {
      errors.phone_number = "Mobile is required!";
      document.getElementById("phone_number").classList.add("is-danger");
    }
    if (!userProfile.country) {
      errors.country = "Country is required!";
      document.getElementById("country").classList.add("is-danger");
    }
    if (!userProfile.profile_image) {
      errors.profile_image = "image is required!";
      document.getElementById("profile_image").classList.add("is-danger");
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Form validation

    const validationErrors = validate();
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors)?.length === 0) {
      try {
        document.getElementById("loaing").classList.remove("hidden__input");
        userProfile.cognitoId = authContext?.auth?.User?.attributes?.sub;
        //add user or update user
        await AddUserProfile(userProfile);
        if (userProfile.imageChanged) {
          await UploadToS3(
            userProfile.profile_image,
            authContext?.auth?.User?.attributes?.sub +
              "/profile-image/" +
              "profileImage.png"
          );
        }
        document.getElementById("loaing").classList.add("hidden__input");
        //show dialog
        setShowError(true);
        setExceptionError("Operation Completed Successfully");
        setMassageTitle("Info");
        localStorage.setItem("profile-status", USER_PROFILE_STATUS_CREATED);
      } catch (error) {
        setShowError(true);
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setExceptionError(error.message);
        setMassageTitle("Error");
        document.getElementById("loaing").classList.add("hidden__input");
      }
    }
  };

  const onInputChange = (event) => {
    const regex = checkRegex(event.target?.type);
    if (event.target?.type === "select-one") {
      setUserProfile({
        ...userProfile,
        ...{ [event.target.id]: event.target.value },
      });
      setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
      document.getElementById(event.target.id)?.classList?.remove("is-danger");
    } else if (event.target?.type === "file") {
      setUserProfile({
        ...userProfile,
        ...{ [event.target.id]: event.target.files[0], imageChanged: true },
      });

      setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
    } else if (event.target?.value === "" || regex.test(event.target?.value)) {
      setUserProfile({
        ...userProfile,
        ...{ [event.target.id]: event.target.value },
      });
      setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
      document.getElementById(event.target.id).classList.remove("is-danger");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // document.getElementById('loaing').classList.remove("hidden__input");
      try {
        document.getElementById("loaing").classList.remove("hidden__input");
        const profile = await GetUserProfile(
          authContext?.auth?.User?.attributes?.email
        );
        let userprofileDetails = {
          user_profile_id: "",
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          country: "",
          accountType: "",
          imageChanged: false,
          profile_image: "",
          username: "",
          facebook: "",
          linkedin: "",
          gender: "",
          experience: "",
          work: "",
          organization: "",
          english: "",
          arabic: "",
          topic: "",
          topicOther: "",
          linkone: "",
          linktwo: "",
          linkthree: "",
          organizationName: "",
          organizationWebsite: "",
          organizationType: "",
          organizationTypeOther: "",
        };

        if (profile?.data?.Item) {
          userprofileDetails = getUserProfileDetails(profile);
          userprofileDetails.profile_image = GetUserProfileImage(
            authContext?.auth?.User?.attributes?.sub
          );
        }
        userprofileDetails.email = authContext?.auth?.User?.attributes?.email;
        userprofileDetails.accountType = authContext?.auth?.UserType;
        userprofileDetails.username = authContext?.auth?.User.username;
        console.log(userprofileDetails);
        if (userprofileDetails.accountType === USER_TYPE_SELLER) {
          setShowWorkerExtraData("block");
          if (userprofileDetails.work === YES) {
            setShowOrganization("block");
          }
          if (userprofileDetails.topic === PREFERED_TOPIC_OTHER) {
            setShowTopicOther("block");
          }
        } else if (userprofileDetails.accountType === USER_TYPE_BUYER) {
          setShowOwnerExtraData("block");
          if (userprofileDetails.organizationType === ORGANIZATION_TYPE_OTHER) {
            setShowOrganizationTypeOther("block");
          }
        }
        setUserProfile(userprofileDetails);
        document.getElementById("loaing").classList.add("hidden__input");
      } catch (error) {
        console.log(error);
        setShowError(true);
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setExceptionError(error.message);
        setMassageTitle("Error");
        document.getElementById("loaing").classList.add("hidden__input");
      }
    };

    fetchData();
  }, [setUserProfile]);

  return (
    <div className="userProfileDetails">
      <div className="hidden__input " id="loaing">
        <Loading />
      </div>
      <Form onSubmit={handleSubmit}>
        <Container className="user__profile__container">
          <Row>
            <Col lg="6">
              <div className="userProfileImage">
                <RoundImagePreview
                  id="profile_image"
                  onChange={(e) => onInputChange(e)}
                  value={GetUserProfileImage(
                    authContext?.auth?.User?.attributes?.sub
                  )}
                />
              </div>
              <p className="error__lable">{formErrors?.profile_image}</p>
            </Col>
            <Col lg="6">
              <div className="content"></div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="6">
              <div className="content">
                First Name
                <Form.Control
                  id="first_name"
                  type="text"
                  placeholder="First Name"
                  value={userProfile.first_name}
                  onChange={(e) => onInputChange(e)}
                />
                <p className="error__lable">{formErrors?.first_name}</p>
              </div>
            </Col>
            <Col lg="6">
              <div className="content">
                Last Name
                <Form.Control
                  id="last_name"
                  placeholder="Last Name"
                  type="text"
                  value={userProfile.last_name}
                  onChange={(e) => onInputChange(e)}
                />
                <p className="error__lable">{formErrors?.last_name}</p>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="6">
              <div className="content">
                Country
                <Form.Select
                  value={userProfile.country}
                  id="country"
                  type="select-one"
                  onChange={(e) => onInputChange(e)}
                >
                  <option value={" "}>Select Your Country</option>
                  {getCountriesList().map((item, index) => (
                    <option value={item.code} key={item.code}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col lg="6">
              <div className="content">
                Mobile
                <Form.Control
                  id="phone_number"
                  placeholder="Mobile"
                  type="number"
                  value={userProfile.phone_number}
                  onChange={(e) => onInputChange(e)}
                />
                <p className="error__lable">{formErrors?.phone_number}</p>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="6">
              <div className="content">
                Email
                <p className="disabled__content">{userProfile.email}</p>
              </div>
            </Col>
            <Col lg="6">
              <div className="content"></div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="6">
              <div className="content">
                Acount Type
                <p className="disabled__content">
                  {getUserTypeText(authContext?.auth?.UserType)}
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div className="content"></div>
            </Col>
          </Row>
          <div
            className="join__extra__data"
            style={{ display: showOwnerExtraData }}
          >
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Organization Name
                  <p className="join__request__details__disabled__content">
                    {userProfile.organizationName}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content"></div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Organization Website
                  <a href={userProfile.organizationWebsite} target="_blank">
                    <p className="join__request__details__disabled__content">
                      {userProfile.organizationWebsite}
                    </p>
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  Organization Type
                  <p className="join__request__details__disabled__content">
                    {getUserOrganizationType(userProfile.organizationType)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content"></div>
              </Col>
              <Col lg="6">
                <div
                  className="join__content"
                  display={showOrganizationTypeOther}
                >
                  Organization Type
                  <p className="join__request__details__disabled__content">
                    {userProfile.organizationTypeOther}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="join__extra__data"
            style={{ display: showWorkerExtraData }}
          >
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Linkedin
                  <a href={userProfile.linkedin} target="_blank">
                    <p className="join__request__details__disabled__content">
                      {userProfile.linkedin}
                    </p>
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  facebook
                  <a href={userProfile.facebook} target="_blank">
                    <p className="join__request__details__disabled__content">
                      {userProfile.facebook}
                    </p>
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Gender
                  <p className="join__request__details__disabled__content">
                    {getGender(userProfile.gender)}
                  </p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Years of experience
                  <p className="join__request__details__disabled__content">
                    {userProfile.experience}
                  </p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Are you Working with a Fact Checking organization?
                  <p className="join__request__details__disabled__content">
                    {userProfile.work === YES ? "Yes" : "No"}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content" display={showOrganization}>
                  Organization name
                  <p className="join__request__details__disabled__content">
                    {userProfile.organization}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  English proficiency
                  <p className="join__request__details__disabled__content">
                    {getLanguageProficiency(userProfile.english)}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  Arabic proficiency
                  <p className="join__request__details__disabled__content">
                    {getLanguageProficiency(userProfile.arabic)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  Prefered topic
                  <p className="join__request__details__disabled__content">
                    {getPreferedTopic(userProfile.topic)}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content" display={showTopicOther}>
                  Prefered topic
                  <p className="join__request__details__disabled__content">
                    {getPreferedTopic(userProfile.topicOther)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Links to items you fact checked</div>
              </Col>
            </Row>
            <Row>
              <div className="join__content">
                <a href={userProfile.linkone} target="_blank">
                  <p className="join__request__details__disabled__content">
                    {userProfile.linkone}
                  </p>
                </a>
              </div>
            </Row>
            <Row>
              <div className="join__content">
                <a href={userProfile.linktwo} target="_blank">
                  <p className="join__request__details__disabled__content">
                    {userProfile.linktwo}
                  </p>
                </a>
              </div>
            </Row>
            <Row>
              <div className="join__content">
                <a href={userProfile.linkthree} target="_blank">
                  <p className="join__request__details__disabled__content">
                    {userProfile.linkthree}
                  </p>
                </a>
              </div>
            </Row>
          </div>
          {!showError && (
            <Button
              className="createButton"
              size="nd"
              block="true"
              type="submit"
            >
              Save
            </Button>
          )}
        </Container>
      </Form>

      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{massageTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {exceptionError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="createButton" onClick={() => setShowError(false)}>
            Ok
          </Button>
          {/* <Button>
            {"cancel"}
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserProfileDetails;
