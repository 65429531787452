import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Button, Alert, Spinner } from "react-bootstrap";
import AuthContext from "../../context/AuthProvider";
import {
  CreateEscrowTransaction,
  GetEscrowPaymentLink,
} from "../../store/services/escrow";
import {
  AcceptWorker,
  InviteWorker,
  UpdatePayment,
} from "../../store/services/tasks";
import "./Applicantlisting.css";

const Applicantlisting = ({
  taskId,
  taskTitle,
  Name,
  proposal,
  price,
  image,
  email,
  action,
  onSubmit,
}) => {
  const [worekerAccepted, setWorekerAccepted] = useState(false);
  const authContext = useContext(AuthContext);
  const handleAcceptWorker = async () => {
    try {
      // const apply = await AcceptWorker(taskId, email);
      setWorekerAccepted(true);
      // const createEscrowTransaction = await CreateEscrowTransaction(
      //   taskId,
      //   authContext?.auth?.User?.attributes?.email,
      //   email,
      //   price,
      //   taskTitle
      // );
      // const updatePayment = await UpdatePayment(
      //   taskId,
      //   createEscrowTransaction.data.id
      // );

      // const getpaymentURL = await GetEscrowPaymentLink(
      //   createEscrowTransaction.data.id,
      //   "https://master.d2q4psjf6i2g3l.amplifyapp.com/jobs/user-jobs"
      // );
      // if (getpaymentURL.data === null) {
      //   document.getElementById("alert").classList.remove("hidden__input");
      // } else {
      //   window.location.replace(getpaymentURL.data.landing_page);
      // }

      onSubmit(action,taskId, email, price, taskTitle);
    } catch (error) {}
  };

  const handleInviteWorker = async () => {
    try {
      // const invite = await InviteWorker(taskId, email);
      setWorekerAccepted(true);

      onSubmit(action,taskId, email);
    } catch (error) {}
  };

  const handleSubmit = () => {
    if (action === "Accept") {
      handleAcceptWorker();
    } else {
      handleInviteWorker();
    }
  };

  return (
    <div className="applicant__listing">
      <div className="applicant__listing__image">
        <img
          src={image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
          }}
          alt="applicant listing"
        />
      </div>
      <div className="applicant__listing__content">
        <div className="applicant__listing__content__text">
          <div className="applicant__listing__content__text__title">
            <h2>{Name}</h2>
          </div>
          <div className="applicant__listing__content__descripttion">
            <p>{proposal}</p>
          </div>
        </div>
        {price && (
          <div className="applicant__listing__content__tags">
            <div className="tag__item">
              <p className="tag">${price}</p>
            </div>
          </div>
        )}
      </div>
      <div className="applicant__listing__accept">
        {!worekerAccepted && (
          <Button className="createButton" onClick={handleSubmit}>
            {action}
          </Button>
        )}
      </div>
    </div>
  );
};
export default Applicantlisting;
