import React, { useContext, useEffect, useState } from "react";
import "./Jobdetails.css";
import { Alert, Button, Form, Tab, Tabs } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Loading from "../../components/loading/loading";
import AuthContext from "../../context/AuthProvider";
import {
  AddTask,
  Apply,
  UpdateWorkerTask,
  ChangeState,
  UpdateWorkerTaskComment,
  InviteWorker,
  GetTaskById,
  RemoveInvitation,
  UpdatePayment,
  AcceptWorker,
  UpdateTask,
} from "../../store/services/tasks";
import { getTaskFromResponseListItem } from "../../helpers/taskFromList";
import {
  UploadToS3,
  ListFilesFromS3,
} from "../../store/services/uploadImageToS3";
import Applicantlisting from "../../components/applicantListing/applicantlisting";
import {
  AddUserNotification,
  GetUserProfile,
  GetUserProfileImage,
  GetUsersByRole,
  Subscribe,
  Unsubscribe,
} from "../../store/services/user";
import AttatchmentList from "../../components/AttatchmentList/AttatchmentList.js";
import {
  CreateEscrowTransaction,
  GetEscrowPaymentLink,
} from "../../store/services/escrow";
import {
  USER_PROFILE_STATUS_CREATED,
  USER_TYPE_SELLER,
} from "../../store/constants/users";
import checkFileTypeSupported from "../../utl/checkSupportedFiles";
import { AlertDialog } from "../../components/dialog/alertDialog";
import Chat from "../Chat/Chat";
import { AddChat } from "../../store/services/chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  TASK_ACTION_ACCEPT,
  TASK_ACTION_APPLY,
  TASK_ACTION_CREATE,
  TASK_ACTION_DELIVERED,
  TASK_ACTION_SEND,
} from "../../store/constants";
import { sesSandboxSendEmail } from "../../store/services/SES";
import getUserProfileDetails from "../../helpers/userProfile";

const JobDetails = (props) => {
  const action = props.action;
  const authContext = useContext(AuthContext);
  const [reject, setRegect] = useState("");
  const [s3Key, setS3Key] = useState("");
  const [fileList, setFileList] = useState([]);
  const [workerFileList, setWorkerFileList] = useState([]);
  const [displayOptions, setDisplayOptions] = useState({
    title: true,
    price: true,
    description: true,
    deadline: true,
    applicants: false,
    btn: true,
    attachments: true,
    proposal: true,
    workerPrice: true,
    workerAttachments: false,
    workerText: false,
    btnReject: false,
    confirmationAria: false,
    confirmationAriaText: false,
    attachmentFiles: false,
    chat: false,
    actions: false,
  });
  const [jobDetails, setJobDetails] = useState({
    title: "",
    description: "",
    price: "",
    deadline: "",
    image:
      "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png",
    attachments: [""],
    applicants: [],
    genres: [],
    id: "",
    taskConclusions: [],
    task_Comment: [],
    taskState: "",
  });
  const [attachments, setAttachments] = useState();
  const [formErrors, setFormErrors] = useState();
  useState(false);
  const [buttonName, setButtonName] = useState("Save");
  const [dialogMassage, setDialogMassage] = useState({
    massageTitle: "Error",
    massageContent: "",
  });
  const [showDialog, setShowDialog] = useState(false);
  const [proposal, setProposal] = useState("");
  const [workerComment, setWorkerComment] = useState("");

  const [workerPrice, setWorkerPrice] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [inviteList, setInviteList] = useState([]);
  const [invited, setInvited] = useState([]);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  let today = new Date();
  let date =
    today.getFullYear() +
    "-" +
    parseInt(today.getMonth() + 1) +
    "-" +
    today.getDate();
  const formatDate = date;

  const getUsersForInvitation = async () => {
    if (action === TASK_ACTION_ACCEPT) {
      const users = await GetUsersByRole(USER_TYPE_SELLER);
      setInviteList(users.data.Items);
    }
  };

  useEffect(() => {
    const fetchData = async (taskId) => {
      try {
        document.getElementById("loaing").classList.remove("hidden__input");
        const taskProfile = await GetTaskById(taskId);
        const job = getTaskFromResponseListItem(taskProfile.data.Item);
        setJobDetails(job);
        setApplicants(taskProfile.data.Item.applicants);
        setInvited(taskProfile.data.Item.invitations["py/set"]);
        if (authContext?.auth?.UserType === USER_TYPE_SELLER) {
          setShowSubscribe(true);
          if (taskProfile?.data?.subscriptions) {
            const subscriptions = taskProfile?.data?.subscriptions["py/set"];
            if (
              subscriptions.some(
                (user) => authContext?.auth?.User?.attributes?.email === user
              )
            ) {
              setSubscribed(true);
            }
          }
        }
      } catch (error) {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Error",
          massageContent: error.message,
        });
      } finally {
        document.getElementById("loaing").classList.add("hidden__input");
      }
    };
    if (action === TASK_ACTION_CREATE) {
      setButtonName("Create");
      let profileImageSource =
        "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
      if (
        localStorage.getItem("profile-status") === USER_PROFILE_STATUS_CREATED
      ) {
        profileImageSource = GetUserProfileImage(
          authContext?.auth?.User?.attributes?.sub
        );
      }
      setJobDetails({
        title: "",
        description: "",
        price: "",
        deadline: "",
        image: profileImageSource,
        attachments: [""],
        applicants: [],
        genres: [],
        id: "",
        taskConclusions: [],
        task_Comment: [],
      });
    } else {
      // const job = getTaskFromResponseListItem(props.job);
      // setJobDetails(job);
      // setApplicants(props.job.applicants);
      fetchData(props.job.id);
      handleAttatchment();
      handleWorkerAttatchment();
    }

    if (action === "view") {
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        btn: false,
        attachments: false,
        attachmentFiles: true,
        actions: false,
      });
    }
    if (action === TASK_ACTION_APPLY) {
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        attachments: false,
        btn: true,
        proposal: true,
        workerPrice: true,
        attachmentFiles: true,
        actions: false,
      });
      setButtonName("Apply");
    }
    if (action === TASK_ACTION_ACCEPT) {
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: true,
        btn: false,
        attachmentFiles: true,
        actions: true,
      });
    }
    if (action === TASK_ACTION_DELIVERED) {
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        workerAttachments: false,
        btn: true,
        btnReject: true,
        confirmationAria: true,
        confirmationAriaText: true,
        attachmentFiles: true,
        actions: false,
        // attachments:true,
      });
      setButtonName("confirm");
      setRegect("reject");
    }
    if (action === "Closed") {
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        btn: false,
        attachmentFiles: true,
        actions: false,
      });
    }
    if (action === TASK_ACTION_SEND) {
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        btn: true,
        attachments: false,
        workerAttachments: true,
        workerText: true,
        attachmentFiles: true,
        actions: false,
      });
      setButtonName(TASK_ACTION_SEND);
    }
  }, [
    setJobDetails,
    setDisplayOptions,
    setApplicants,
    setButtonName,
    setSubscribed,
  ]);

  const handleProposalChanged = (event) => {
    setProposal(event.target.value);
  };
  const handleWorkerCommentChanged = (event) => {
    setWorkerComment(event.target.value);
  };
  const onInputChange = (event) => {
    if (event.target?.type === "file") {
      setJobDetails({
        ...jobDetails,
        ...{ [event.target.id]: event.target.files[0] },
      });
    } else {
      setJobDetails({
        ...jobDetails,
        ...{ [event.target.id]: event.target.value },
      });
    }
    setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  const validate = () => {
    const errors = {};
    if (!jobDetails.title) {
      errors.title = "Title is required!";
      document.getElementById("title").classList.add("is-danger");
    }

    if (!jobDetails.price) {
      errors.price = "Price is required!";
      document.getElementById("price").classList.add("is-danger");
    }

    if (!jobDetails.deadline) {
      errors.deadline = "Deadline is required!";
      document.getElementById("deadline").classList.add("is-danger");
    }

    if (!jobDetails.description) {
      errors.description = "Description is required!";
      document.getElementById("description").classList.add("is-danger");
    }
    if (!jobDetails.price) {
      errors.price = "price is required!";
      document.getElementById("price").classList.add("is-danger");
    }

    return errors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Form validation
    const validationErrors = validate();
    setFormErrors(validationErrors);
    let result = false;
    if (Object.keys(validationErrors)?.length === 0) {
      if (action === TASK_ACTION_CREATE) {
        result = await handleAddTask();
        if (result) {
          const profile = await GetUserProfile(
            authContext?.auth?.User?.attributes?.email
          );
          const userProfile = getUserProfileDetails(profile);
          if (userProfile?.subscriptions) {
            const subscriptions = userProfile?.subscriptions.filter((n) => n);
            if (subscriptions.length > 0) {
              //after moving from SES sandbox to production change from map to :
              await sesSandboxSendEmail(
                jobDetails,
                TASK_ACTION_CREATE,
                subscriptions, //this is changed
                authContext?.auth?.User?.username
              );
              //after that change inside the method (sesSendTemplateEmail) to take array of emails
              subscriptions.map(async (subscription) => {
                // await sesSandboxSendEmail(
                //   jobDetails,
                //   TASK_ACTION_CREATE,
                //   subscription,
                //   authContext?.auth?.User?.username
                // );
                await AddUserNotification(
                  subscription,
                  "unread",
                  authContext?.auth?.User?.username +
                    " has added a new fact-checking job: " +
                    jobDetails.title,
                  new Date().toLocaleString()
                );
              });
            }
          }
        }
      } else if (action === TASK_ACTION_APPLY) {
        result = await apllyForTask();
        if (result) {
          await AddUserNotification(
            jobDetails.owner,
            "unread",
            authContext?.auth?.User?.username +
              " applied to your fact-checking job : " +
              jobDetails.title,
            new Date().toLocaleString()
          );
          sesSandboxSendEmail(
            jobDetails,
            TASK_ACTION_APPLY,
            [jobDetails.owner],
            authContext?.auth?.User?.username
          );
        }
      } else if (action === TASK_ACTION_SEND) {
        result = handleWorkerComment();
      } else if (action === TASK_ACTION_DELIVERED) {
        result = handleConfirm();
      } else if (action === TASK_ACTION_ACCEPT) {
        //edit
        result = await handleUpdateTask();
      }
      if (result) {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Info",
          massageContent: "Operation Completed Successfully",
        });
      }
    }
  };
  const handleWorkerAction = async (
    action,
    taskId,
    email,
    price,
    taskTitle
  ) => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: true,
        attachments: false,
      });
      if (action === "Accept") {
        await handleAcceptWorker(taskId, email, price, taskTitle);
      } else {
        await handleInviteWorker(taskId, email);
      }
      document.getElementById("loaing").classList.add("hidden__input");
    } catch (error) {
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };

  const handleAttachmentsChange = (event) => {
    let files = event.target.files;
    for (let file of files) {
      if (file.size > 512000000) {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Error",
          massageContent: "Maximum file size is 500MB",
        });
        event.target.value = null;
        return;
      }
      if (!checkFileTypeSupported(file.type)) {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Error",
          massageContent: "File type not supported",
        });
        event.target.value = null;
        return;
      }
    }

    setAttachments(event.target.files);
  };

  const handleConfirm = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      // const getEscrowTransactionInfo = await GetEscrowTransactionInfo(
      //   getTransactionId(jobDetails.payment.tarnsactionId)
      // );
      // if (!getEscrowTransactionInfo?.data?.items[0]?.status?.accepted) {
      //   setShowDialog(true);
      //   setExceptionError(
      //     "Please accept the transaction on your escrow account first"
      //   );
      // } else {
      // console.log("getEscrowTransactionInfo", getEscrowTransactionInfo);
      await ChangeState(props.job.id, "confirm");
      // }
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        attachments: false,
        attachmentFiles: true,
        confirmationAria: true,
        confirmationAriaText: false,
        btn: false,
        actions: false,
      });
      document.getElementById("loaing").classList.add("hidden__input");
      return true;
    } catch (error) {
      console.log(error);
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  const handleWorkerComment = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");

      // const getEscrowTransactionInfo = await GetEscrowTransactionInfo(
      //   getTransactionId(jobDetails.payment.tarnsactionId)
      // );
      // if (!getEscrowTransactionInfo.data.items[0].schedule[0].status.secured) {
      //   setShowDialog(true);
      //   setExceptionError("payment is not prossesed yet");
      // } else {
      await UpdateWorkerTask(props.job.id, proposal, TASK_ACTION_SEND);
      if (attachments) {
        for (let attachment of attachments) {
          const upload = await UploadToS3(
            attachment,
            props.job.id + "/workerAttachments/" + attachment.name
          );
        }
      }
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        attachments: false,
        workerAttachments: true,
      });
      // }
      return true;
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };

  const handleAttatchment = async () => {
    const file = await ListFilesFromS3(
      props.job.id + "/attachments",
      (err, data) => {
        if (err) {
        } else {
          setS3Key(data.Contents);

          setFileList(data.Contents);
        }
      }
    );
  };

  const handleWorkerAttatchment = async () => {
    await ListFilesFromS3(props.job.id + "/workerAttachments", (err, data) => {
      if (err) {
      } else {
        setS3Key(data.Contents);

        setWorkerFileList(data.Contents);
      }
    });
  };

  const handleWorkerComments = async () => {
    try {
      if (document.getElementById("workerComment").value === "") {
        document.getElementById("workerComment").classList.add("is-danger");
      } else {
        document.getElementById("workerComment").classList.remove("is-danger");
        document.getElementById("loaing").classList.remove("hidden__input");
        await UpdateWorkerTaskComment(props.job.id, workerComment, "reject");
        setDisplayOptions({
          title: false,
          price: false,
          description: false,
          deadline: false,
          applicants: false,
          attachments: false,
          workerAttachments: false,
        });
      }
      document.getElementById("loaing").classList.add("hidden__input");
    } catch (error) {
      setShowDialog(true);
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };

  const handleAddTask = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      const addtask = await AddTask(
        jobDetails.title,
        jobDetails.description,
        jobDetails.image,
        jobDetails.price,
        jobDetails.deadline,
        jobDetails.attachments,
        authContext?.auth?.User?.attributes?.email
      );
      if (attachments) {
        for (let attachment of attachments) {
          const upload = await UploadToS3(
            attachment,
            addtask.data.taskId + "/attachments/" + attachment.name
          );
        }
      }

      const chat = await AddChat(addtask.data.taskId);
      // console.log('-----new chat added---->>',chat)

      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        attachments: false,
      });
      document.getElementById("loaing").classList.add("hidden__input");
      return true;
    } catch (error) {
      console.log("---errr----->", error);
      setShowDialog(true);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };

  const handleUpdateTask = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      const addtask = await UpdateTask(
        jobDetails.id,
        jobDetails.title,
        jobDetails.description,
        jobDetails.image,
        jobDetails.price,
        jobDetails.deadline
      );
      if (attachments) {
        for (let attachment of attachments) {
          const upload = await UploadToS3(
            attachment,
            addtask.data.taskId + "/attachments/" + attachment.name
          );
        }
      }
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        attachments: false,
      });
      document.getElementById("loaing").classList.add("hidden__input");
      return true;
    } catch (error) {
      console.log("---errr----->", error);
      setShowDialog(true);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  const handleDeleteTask = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      const deleteTask = await ChangeState(jobDetails.id, "delete");
      setDisplayOptions({
        title: false,
        price: false,
        description: false,
        deadline: false,
        applicants: false,
        attachments: false,
      });
      document.getElementById("loaing").classList.add("hidden__input");
      return true;
    } catch (error) {
      console.log("---errr----->", error);
      setShowDialog(true);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  const apllyForTask = async () => {
    try {
      if (document.getElementById("worker_proposal").value === "") {
        document.getElementById("worker_proposal").classList.add("is-danger");
      } else if (
        applicants.some(
          (user) => authContext?.auth?.User?.attributes?.email === user.email
        )
      ) {
        setDialogMassage({
          massageTitle: "Alert",
          massageContent: "You have already applied for this job",
        });
        setShowDialog(true);
      } else {
        document
          .getElementById("worker_proposal")
          .classList.remove("is-danger");
        document.getElementById("loaing").classList.remove("hidden__input");
        const apply = await Apply(
          props.job.id,
          authContext?.auth?.User?.attributes?.email,
          proposal,
          workerPrice,
          authContext?.auth?.User?.attributes?.sub,
          authContext?.auth?.User?.username
        );
        if (
          invited.some(
            (user) => authContext?.auth?.User?.attributes?.email === user
          )
        ) {
          const removeInvitation = await RemoveInvitation(
            props.job.id,
            authContext?.auth?.User?.attributes?.email
          );
        }

        setDisplayOptions({
          title: false,
          price: false,
          description: false,
          deadline: false,
          applicants: false,
          attachments: false,
          attachmentFiles: true,
        });
        //send email
        document.getElementById("loaing").classList.add("hidden__input");
        return true;
      }
    } catch (error) {
      document.getElementById("loaing").classList.remove("hidden__input");
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      setShowDialog(true);
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  const handlenoImage = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src =
      "https://afcn-marketplace.s3.eu-west-1.amazonaws.com/assets/person-4.png";
  };

  const handleAcceptWorker = async (taskId, email, price, taskTitle) => {
    try {
      const apply = await AcceptWorker(taskId, email);
      // const createEscrowTransaction = await CreateEscrowTransaction(
      //   taskId,
      //   authContext?.auth?.User?.attributes?.email,
      //   email,
      //   price,
      //   taskTitle
      // );
      // const updatePayment = await UpdatePayment(
      //   taskId,
      //   createEscrowTransaction.data.id
      // );

      // const getpaymentURL = await GetEscrowPaymentLink(
      //   createEscrowTransaction.data.id,
      //   "https://master.d2q4psjf6i2g3l.amplifyapp.com/jobs/user-jobs"
      // );
      sesSandboxSendEmail(
        jobDetails,
        TASK_ACTION_ACCEPT,
        [email],
        authContext?.auth?.User?.username
      );
      await AddUserNotification(
        email,
        "unread",
        authContext?.auth?.User?.username +
          " approved your application for: " +
          jobDetails.title,
        new Date().toLocaleString()
      );
      // if (getpaymentURL.data === null) {
      // document.getElementById("alert").classList.remove("hidden__input");
      // } else {
      //   window.location.replace(getpaymentURL.data.landing_page);
      // }
      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Info",
        massageContent: "Operation Completed Successfully",
      });
    } catch (error) {
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
    }
  };

  const handleInviteWorker = async (taskId, email) => {
    try {
      const invite = await InviteWorker(taskId, email);
    } catch (error) {}
  };
  const onEdit = () => {
    setDisplayOptions({
      title: true,
      price: true,
      description: true,
      deadline: true,
      applicants: false,
      btn: true,
      attachments: true,
      proposal: true,
      workerPrice: true,
      workerAttachments: false,
      workerText: false,
      btnReject: false,
      confirmationAria: false,
      confirmationAriaText: false,
      attachmentFiles: false,
      chat: false,
      actions: false,
    });
  };
  const onDelete = () => {
    setShowDialog(true);
    setDialogMassage({
      massageTitle: "Confirm Delete",
      massageContent: "Are You Sure You Want To Delete This Job",
    });
  };
  const onSubscribe = async () => {
    try {
      document.getElementById("loaing").classList.remove("hidden__input");
      let message = "You have been subscribed to the mailing list";
      if (subscribed) {
        const unsub = await Unsubscribe(
          jobDetails.owner,
          authContext?.auth?.User?.attributes?.email
        );
        message = "You have been unsubscribed from the mailing list";
        setSubscribed(false);
      } else {
        const res = await Subscribe(
          jobDetails.owner,
          authContext?.auth?.User?.attributes?.email
        );
        setSubscribed(true);
      }

      setShowDialog(true);
      setDialogMassage({
        massageTitle: "Info",
        massageContent: message,
      });
    } catch (error) {
      setDialogMassage({
        massageTitle: "Error",
        massageContent: error.message,
      });
      setShowDialog(true);
    } finally {
      document.getElementById("loaing").classList.add("hidden__input");
    }
  };
  const handleDialogAgree = async () => {
    setShowDialog(false);
    if (dialogMassage.massageTitle === "Confirm Delete") {
      let result = false;
      result = await handleDeleteTask();
      if (result) {
        setShowDialog(true);
        setDialogMassage({
          massageTitle: "Info",
          massageContent: "Operation Completed Successfully",
        });
      }
    }
  };
  return (
    <div className="jobdetails">
      {jobDetails.taskState === "open" && (
        <Chat id="chat" taskId={props.job.id} />
      )}

      <div className="hidden__input " id="loaing">
        <Loading />
      </div>

      <Form onSubmit={handleSubmit} style={{ marginBottom: 51 }}>
        <div className="jobdetails__content">
          {displayOptions.actions && (
            <div className="jobdetails__actions">
              <Button
                id="btnEdit"
                className="p-2 mx-3"
                variant="success-c"
                size="nd"
                block="true"
                onClick={() => {
                  onEdit();
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                id="btnDelete"
                className="p-2"
                variant="success-c"
                size="nd"
                block="true"
                onClick={() => {
                  onDelete();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )}
          <div className="jobdetails__image">
            <img
              className="jobdetails__round__image"
              src={jobDetails.image}
              onError={(currentTarget) => handlenoImage(currentTarget)}
            ></img>
            {showSubscribe && (
              <div className="jobdetails__owner">
                <Button
                  id="btnSubscribe"
                  className="p-2"
                  variant="success-c"
                  size="nd"
                  block="true"
                  onClick={() => {
                    onSubscribe();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    className="btn__icon__margen"
                  />
                  {subscribed ? "Unsubscribe" : "Subscribe"}
                </Button>
              </div>
            )}
          </div>
          <div className="jobdetails__info">
            <div className="jobdetails__info__inputs">
              {displayOptions.title && (
                <>
                  <div className="jobdetails__info__inputs__title">
                    Title
                    <Form.Control
                      id="title"
                      placeholder="Title"
                      value={jobDetails.title}
                      onChange={(e) => onInputChange(e)}
                    ></Form.Control>
                  </div>
                  <br />
                </>
              )}
              {!displayOptions.title && (
                <>
                  <div className="jobdetails__info__inputs__title">
                    Title
                    <p className="jobdetails__disabled__content">
                      {jobDetails.title}
                    </p>
                  </div>
                  <br />
                </>
              )}
              {displayOptions.price && (
                <>
                  <div className="jobdetails__info__inputs__price">
                    Price
                    <Col sm={3}>
                      <Form.Control
                        id="price"
                        placeholder="Price in $"
                        value={jobDetails.price}
                        onChange={(e) => onInputChange(e)}
                        type="number"
                      ></Form.Control>
                    </Col>
                  </div>

                  <br />
                </>
              )}
              {!displayOptions.price && (
                <>
                  <Col sm={3}>
                    <div className="jobdetails__info__inputs__price">
                      Price
                      <p className="jobdetails__disabled__content">
                        {jobDetails.price} $
                      </p>
                    </div>
                    <br />
                  </Col>
                </>
              )}
              {displayOptions.deadline && (
                <>
                  <div className="jobdetails__info__inputs__deadline">
                    Deadline
                    <Col sm={3}>
                      <Form.Control
                        id="deadline"
                        placeholder="Deadline"
                        value={jobDetails.deadline}
                        onChange={(e) => onInputChange(e)}
                        type="date"
                        min={formatDate}
                      ></Form.Control>
                    </Col>
                  </div>
                </>
              )}
              {!displayOptions.deadline && (
                <>
                  <Col sm={3}>
                    <div className="jobdetails__info__inputs__deadline">
                      Deadline
                      <p className="jobdetails__disabled__content">
                        {jobDetails.deadline}
                      </p>
                    </div>
                  </Col>
                </>
              )}
            </div>
          </div>
          <div className="jobdetails__tabs">
            <Tabs
              variant="pills"
              defaultActiveKey="Description"
              id="detailsTab"
              className="mb-3"
              onSelect={getUsersForInvitation}
              justify
            >
              <Tab eventKey="Description" title="Description">
                <div className="jobdetails__tab__content">
                  {displayOptions.deadline && (
                    <Form.Control
                      id="description"
                      as="textarea"
                      rows={5}
                      value={jobDetails.description}
                      onChange={(e) => onInputChange(e)}
                    />
                  )}
                  {!displayOptions.deadline && (
                    <p className="jobdetails__disabled__content">
                      {jobDetails.description}
                    </p>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Attachments" title="Attachments">
                <div className="jobdetails__tab__content">
                  {displayOptions.attachmentFiles && (
                    <AttatchmentList allFile={fileList} />
                  )}
                  {displayOptions.attachments && (
                    <Form.Control
                      size="sm"
                      type="file"
                      accept="image/*,video/*,application/pdf,.csv,.doc,.docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => handleAttachmentsChange(e)}
                      disabled={!displayOptions.attachments}
                      multiple
                    />
                  )}
                </div>
              </Tab>
              {displayOptions.applicants && (
                <Tab eventKey="Applicants" title="Applicants">
                  <div className="jobdetails__tab__content">
                    <div>
                      {applicants.map((item, index) => (
                        <Applicantlisting
                          Name={item.applicantUsername}
                          proposal={item.propsal}
                          price={jobDetails.price}
                          taskId={props.job.id}
                          email={item.email}
                          image={GetUserProfileImage(item.applicantUserId)}
                          onSubmit={handleWorkerAction}
                          taskTitle={jobDetails.title}
                          action="Accept"
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                </Tab>
              )}
              {displayOptions.applicants && (
                <Tab eventKey="Invite" title="Invite">
                  <div className="jobdetails__tab__content">
                    {inviteList?.length > 0 && (
                      <div>
                        {inviteList.map(
                          (item, index) =>
                            !applicants.some(
                              (applicant) => item.email === applicant.email
                            ) &&
                            !invited.some((user) => item.email === user) && (
                              <Applicantlisting
                                Name={item.first_name + " " + item.last_name}
                                proposal={item.email}
                                price={""}
                                taskId={props.job.id}
                                email={item.email}
                                image={GetUserProfileImage(item.cognitoId)}
                                onSubmit={handleWorkerAction}
                                taskTitle={"title"}
                                action="Invite"
                                key={index}
                              />
                            )
                        )}
                      </div>
                    )}
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
          <div className="line"></div>
          {action === TASK_ACTION_APPLY && (
            <div className="jobdetails__apply">
              <h1>Apply For This Job</h1>
              <div className="jobdetails__apply__proposal">
                <h3>Proposal</h3>
                {displayOptions.proposal && (
                  <Form.Control
                    id="worker_proposal"
                    as="textarea"
                    rows={5}
                    onChange={(e) => handleProposalChanged(e)}
                    value={proposal}
                  />
                )}
                {!displayOptions.proposal && (
                  <p className="jobdetails__disabled__content">{proposal}</p>
                )}
              </div>
            </div>
          )}
          {displayOptions.workerAttachments && (
            <>
              {jobDetails.taskComment && (
                <>
                  <br />
                  <p>Employer comments</p>
                  {jobDetails.taskComment.map(
                    (item, index) =>
                      item && (
                        <p
                          key={index}
                          className="jobdetails__disabled__content mt-2"
                        >
                          {item}
                        </p>
                      )
                  )}
                </>
              )}

              <div className="jobdetails__worker__coments">
                <div className="line"></div>
                <br />
                <p>Conclusions</p>
                <Form.Control
                  id="proposal"
                  as="textarea"
                  rows={5}
                  // required
                  onChange={(e) => {
                    handleProposalChanged(e);
                    onInputChange(e);
                  }}
                  value={proposal}
                  className="mt-3 mb-3"
                />
                upload your files
                <Form.Control
                  size="sm"
                  type="file"
                  accept="image/*,video/*,application/pdf,.csv,.doc,.docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => handleAttachmentsChange(e)}
                  disabled={displayOptions.attachments}
                  className="mt-1"
                  multiple
                />
              </div>
            </>
          )}
          {displayOptions.confirmationAria && (
            <div className="mt-3">
              fact checker Conclusions
              {jobDetails.taskConclusions.map(
                (item, index) =>
                  item && (
                    <p
                      key={index}
                      className="jobdetails__disabled__content mt-2"
                    >
                      {item}
                    </p>
                  )
              )}
              <div>
                fact checker attachments
                <AttatchmentList allFile={workerFileList} />
              </div>
              {displayOptions.confirmationAriaText && (
                <>
                  <div className="line"></div>
                  <br />
                  <p> write your comment </p>

                  <Form.Control
                    id="workerComment"
                    as="textarea"
                    rows={5}
                    onChange={(e) => {
                      handleWorkerCommentChanged(e);
                      onInputChange(e);
                    }}
                    // required
                    value={workerComment}
                    className="mt-3 mb-3"
                  />
                </>
              )}
              <div className="line"></div>
            </div>
          )}
          {displayOptions.btn && (
            <div className="jobdetails__footer">
              <div className="jobdetails__footer__content">
                <Button
                  type="submit"
                  variant="success-c"
                  size="nd"
                  block="true"
                  id="btn"
                >
                  {buttonName}
                </Button>
              </div>
              {reject === "reject" && (
                <div className="jobdetails__footer__content">
                  <Button
                    id="btnTask"
                    variant="success-c"
                    size="nd"
                    block="true"
                    onClick={(e) => {
                      onInputChange(e);
                      handleWorkerComments();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Form>
      <AlertDialog
        show={showDialog}
        title={dialogMassage.massageTitle}
        content={dialogMassage.massageContent}
        agreeAction={"Ok"}
        disagreeAction={"cancel"}
        handleDisagree={() => setShowDialog(false)}
        handleAgree={() => handleDialogAgree()}
      ></AlertDialog>
    </div>
  );
};

export default JobDetails;
