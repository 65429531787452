import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./Join.css";
import { useNavigate } from "react-router-dom";
import {
  GENDER_FEMALE,
  GENDER_MALE,
  LANGUAGE_PROFICIENCY_ADVANCED,
  LANGUAGE_PROFICIENCY_BEGINNER,
  LANGUAGE_PROFICIENCY_INTERMEDIATE,
  LANGUAGE_PROFICIENCY_UPPER_INTERMEDIATE,
  NO,
  ORGANIZATION_TYPE_MEDIA_ORGANIZATION,
  ORGANIZATION_TYPE_NGO,
  ORGANIZATION_TYPE_OTHER,
  ORGANIZATION_TYPE_FACT_CHECKING_ORGANIZATION,
  ORGANIZATION_TYPE_UNIVERSITY,
  PREFERED_TOPIC_ENTERTAINMENT,
  PREFERED_TOPIC_HEALTH,
  PREFERED_TOPIC_OTHER,
  PREFERED_TOPIC_POLITICS,
  PREFERED_TOPIC_SCIENCE,
  PREFERED_TOPIC_SOCIAL,
  USER_TYPE_BUYER,
  USER_TYPE_SELLER,
  YES,
} from "../../store/constants";
import Loading from "../../components/loading/loading";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { AddJoinRequest } from "../../store/services/user";
import config from "../../config.json";
import { sesSandboxSendJoinEmail } from "../../store/services/SES";
import checkRegex from "../../utl/regex.js";
import { getCountriesList } from "../../utl/countries";
const JoinForm = () => {
  const [show, setShow] = useState(false);
  const [someErrore, setSomeErrore] = useState("");
  const [showWorkerExtraData, setShowWorkerExtraData] = useState("none");
  const [showOwnerExtraData, setShowOwnerExtraData] = useState("none");
  const [organizationDisabled, setOrganizationDisabled] = useState(true);
  const [organizationTypeOtherDisabled, setOrganizationTypeOtherDisabled] =
    useState(true);
  const [topicOtherDisabled, setTopicOtherDisabled] = useState(true);

  const [status, setStatus] = useState({
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    accountType: "",
    phone: "",
    facebook: "",
    linkedin: "",
    gender: "",
    experience: "",
    organization: "",
    english: "",
    arabic: "",
    topic: "",
    topicOther: "",
    linkone: "",
    linktwo: "",
    linkthree: "",
    work: "",
    organizationName: "",
    organizationCountry: "",
    organizationWebsite: "",
    organizationType: "",
    organizationTypeOther: "",
    errors: {
      cognito: null,
    },
  });
  const [formErrors, setFormErrors] = useState();
  const navigate = useNavigate();

  const clearErrorState = () => {
    setStatus({
      ...status,
      ...{
        errors: {
          cognito: null,
        },
      },
    });
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const passwordRegex = /^[\S]+.*[\S]+$/;
    const passwordRegex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    // const usernameRegex = /^[^\p{L}^\p{M}^\p{S}^\p{N}^\p{P}]+/;
    const usernameRegex = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d\w]*$/g;
    if (!status.username) {
      errors.username = "Username is required!";
      document.getElementById("username").classList.add("is-danger");
    } else if (!usernameRegex.test(status.username)) {
      errors.username = "This is not a valid username format!";
      document.getElementById("username").classList.add("is-danger");
    }
    if (!status.password) {
      errors.password = "Password is required!";
      document.getElementById("password").classList.add("is-danger");
    } else if (!passwordRegex.test(status.password)) {
      errors.password =
        "This is not a valid password format!" +
        "\n" +
        "Password requirements: " +
        "\n" +
        "Contains at least 8 characters, 1 number, 1 special character, 1 uppercase letter, 1 lowercase letter";
      document.getElementById("password").classList.add("is-danger");
    }

    if (status.confirmpassword !== status.password) {
      errors.confirmpassword = "Password And Confirm Password Do Not Match";
      document.getElementById("confirmpassword").classList.add("is-danger");
    }

    if (!status.confirmpassword) {
      errors.confirmpassword = "Confirm Password is required!";
      document.getElementById("confirmpassword").classList.add("is-danger");
    }

    if (!status.email) {
      errors.email = "Email is required!";
      document.getElementById("email").classList.add("is-danger");
    } else if (!emailRegex.test(status.email)) {
      errors.email = "This is not a valid email format!";
      document.getElementById("email").classList.add("is-danger");
    }
    if (!status.accountType) {
      errors.accountType = "Account type is required!";
      document.getElementById("chkAccountTypeBuyer").classList.add("is-danger");
      document
        .getElementById("chkAccountTypeSeller")
        .classList.add("is-danger");
    }
    if (status.accountType === USER_TYPE_SELLER) {
      if (!status.phone) {
        errors.phone = "Phone is required!";
        document.getElementById("phone").classList.add("is-danger");
      } else {
        const numberRegex = checkRegex("number");
        if (!numberRegex.test(status.phone)) {
          errors.phone = "Invalid Phone Number!";
          document.getElementById("phone").classList.add("is-danger");
        }
      }

      if (!status.facebook) {
        errors.facebook = "facebook link is required!";
        document.getElementById("facebook").classList.add("is-danger");
      }
      if (!status.linkedin) {
        errors.linkedin = "linkedin link is required!";
        document.getElementById("linkedin").classList.add("is-danger");
      }
      if (!status.gender) {
        errors.gender = "Gender is required!";
        document.getElementById("gender").classList.add("is-danger");
      }
      if (!status.experience) {
        errors.experience = "experience is required!";
        document.getElementById("experience").classList.add("is-danger");
      } else {
        const numberRegex = checkRegex("number");
        if (!numberRegex.test(status.experience)) {
          errors.experience = "Invalid years of experience!";
          document.getElementById("experience").classList.add("is-danger");
        }
      }
      if (!status.work) {
        errors.work = "work status is required!";
        document.getElementById("work").classList.add("is-danger");
      }
      if (status.work === YES) {
        if (!status.organization) {
          errors.organization = "organization is required!";
          document.getElementById("organization").classList.add("is-danger");
        }
      }

      if (!status.english) {
        errors.english = "English proficiency is required!";
        document.getElementById("english").classList.add("is-danger");
      }
      if (!status.arabic) {
        errors.arabic = "Arabic proficiency is required!";
        document.getElementById("arabic").classList.add("is-danger");
      }
      if (!status.topic) {
        errors.topic = "prefered topic is required!";
        document.getElementById("topic").classList.add("is-danger");
      }
      if (status.topic === PREFERED_TOPIC_OTHER) {
        if (!status.topicOther) {
          errors.topicOther = "prefered topic is required!";
          document.getElementById("topicOther").classList.add("is-danger");
        }
      }

      if (!status.linkone) {
        errors.linkone = "Link is required!";
        document.getElementById("linkone").classList.add("is-danger");
      }
      if (!status.linktwo) {
        errors.linktwo = "Link is required!";
        document.getElementById("linktwo").classList.add("is-danger");
      }
      if (!status.linkthree) {
        errors.linkthree = "Link is required!";
        document.getElementById("linkthree").classList.add("is-danger");
      }
    } else {
      if (!status.organizationName) {
        errors.organizationName = "Organization Name is required!";
        document.getElementById("organizationName").classList.add("is-danger");
      }
      if (!status.organizationCountry) {
        errors.organizationCountry = "Organization Country is required!";
        document
          .getElementById("organizationCountry")
          .classList.add("is-danger");
      }
      if (!status.organizationWebsite) {
        errors.organizationWebsite = "Organization Website Link is required!";
        document
          .getElementById("organizationWebsite")
          .classList.add("is-danger");
      }
      if (!status.organizationType) {
        errors.organizationType = "Organization Type is required!";
        document.getElementById("organizationType").classList.add("is-danger");
      }
      if (status.organizationType === ORGANIZATION_TYPE_OTHER) {
        if (!status.organizationTypeOther) {
          errors.organizationTypeOther = "organization is required!";
          document
            .getElementById("organizationTypeOther")
            .classList.add("is-danger");
        }
      }
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    clearErrorState();
    const validationErrors = validate();
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors)?.length === 0) {
      const {
        username,
        email,
        password,
        accountType,
        phone,
        facebook,
        linkedin,
        gender,
        experience,
        work,
        organization,
        english,
        arabic,
        topic,
        topicOther,
        linkone,
        linktwo,
        linkthree,
        organizationName,
        organizationCountry,
        organizationWebsite,
        organizationType,
        organizationTypeOther,
      } = status;
      try {
        document.getElementById("loaing").classList.remove("hidden__input");
        const joinResponse = await AddJoinRequest({
          email: email,
          username: username,
          usertype: accountType,
          userString: password,
          phone: phone,
          facebook: facebook,
          linkedin: linkedin,
          gender: gender,
          experience: experience,
          work: work,
          organization: organization,
          english: english,
          arabic: arabic,
          topic: topic,
          topicOther: topicOther,
          linkone: linkone,
          linktwo: linktwo,
          linkthree: linkthree,
          organizationName: organizationName,
          organizationCountry: organizationCountry,
          organizationWebsite: organizationWebsite,
          organizationType: organizationType,
          organizationTypeOther: organizationTypeOther,
          status: "new",
        });
        if (joinResponse.status === 200) {
          sesSandboxSendJoinEmail(config.SES.SOURCE_EMAIL, username, email);
          navigate("/Welcome");
        } else if (joinResponse.status === 208) {
          let err = "Username already exists";
          setShow(true);
          setSomeErrore(err);
        } else if (joinResponse.status === 209) {
          let err = "email already exists";
          setShow(true);
          setSomeErrore(err);
        }
      } catch (error) {
        setShow(true);
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setStatus({
          ...status,
          ...{
            errors: {
              ...status.errors,
              cognito: err,
            },
          },
        });
        setSomeErrore(error.message);
        document.getElementById("loaing").classList.add("hidden__input");
      } finally {
        document.getElementById("loaing").classList.add("hidden__input");
      }
    }
  };

  const onInputChange = (event) => {
    if (
      event.target.id === "organizationType" &&
      event.target.value !== ORGANIZATION_TYPE_OTHER
    ) {
      setStatus({
        ...status,
        ...{ [event.target.id]: event.target.value, organizationTypeOther: "" },
      });
    } else if (event.target.id === "work" && event.target.value !== YES) {
      setStatus({
        ...status,
        ...{ [event.target.id]: event.target.value, organization: "" },
      });
    } else {
      setStatus({ ...status, ...{ [event.target.id]: event.target.value } });
    }

    setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
    document.getElementById(event.target.id).classList.remove("is-danger");

    if (event.target.id === "work") {
      if (event.target.value === YES) {
        setOrganizationDisabled(false);
      } else {
        setOrganizationDisabled(true);
        // setStatus({ ...status, ...{ organization: "" } });
        // setFormErrors({ ...formErrors, ...{ organization: "" } });
        document.getElementById("organization").classList.remove("is-danger");
      }
    }

    if (event.target.id === "organizationType") {
      if (event.target.value === ORGANIZATION_TYPE_OTHER) {
        setOrganizationTypeOtherDisabled(false);
        // setStatus({ ...status, ...{ 'organizationTypeOther': '' } });
      } else {
        setOrganizationTypeOtherDisabled(true);
        // setStatus({ ...status, ...{ 'organizationTypeOther': '' } });
        document
          .getElementById("organizationTypeOther")
          .classList.remove("is-danger");
      }
    }

    if (event.target.id === "topic") {
      if (event.target.value === PREFERED_TOPIC_OTHER) {
        setTopicOtherDisabled(false);
      } else {
        setTopicOtherDisabled(true);
        document.getElementById("topicOther").classList.remove("is-danger");
      }
    }
  };
  const onAccountTypeChange = (event) => {
    if (event.target.id === "chkAccountTypeBuyer") {
      setStatus({ ...status, ...{ accountType: USER_TYPE_BUYER } });
      setShowWorkerExtraData("none");
      setShowOwnerExtraData("block");
    } else {
      setStatus({ ...status, ...{ accountType: USER_TYPE_SELLER } });
      setShowWorkerExtraData("block");
      setShowOwnerExtraData("none");
    }
    setFormErrors({ ...formErrors, ...{ accountType: "" } });
    document
      .getElementById("chkAccountTypeBuyer")
      .classList.remove("is-danger");
    document
      .getElementById("chkAccountTypeSeller")
      .classList.remove("is-danger");
  };

  return (
    <div className="joinform">
      <div className="hidden__input" id="loaing">
        <Loading />
      </div>
      <Form onSubmit={handleSubmit}>
        <h3>Join AFCN</h3>
        <br></br>

        <Container className="join__container">
          <Row>
            <Form.Group controlId="formBasicName">
              <div className="join__user__type">
                <Form.Check className="join__user__type__check">
                  <Form.Check.Input
                    name="type"
                    id="chkAccountTypeSeller"
                    type="radio"
                    onChange={onAccountTypeChange}
                    inline="true"
                  />
                  <Form.Check.Label>Fact-checker</Form.Check.Label>
                </Form.Check>
                <Form.Check id="checkGroup" className="join__user__type__check">
                  <Form.Check.Input
                    name="type"
                    id="chkAccountTypeBuyer"
                    type="radio"
                    onChange={onAccountTypeChange}
                    inline="true"
                  />
                  <Form.Check.Label>
                    Fact-checking Organization - Media Organization - University
                    or College - NGO - Others
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <p className="error__lable">{formErrors?.accountType}</p>
            </Form.Group>
          </Row>
          <Row>
            <Col lg="6">
              <div className="join__content">
                <Form.Group controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={status.email}
                    onChange={(e) => onInputChange(e)}
                  />
                </Form.Group>
                <p className="error__lable">{formErrors?.email}</p>
              </div>
            </Col>
            <Col lg="6">
              <div className="join__content">
                <Form.Group controlId="username">
                  <Form.Control
                    placeholder="User Name"
                    value={status.username}
                    onChange={(e) => onInputChange(e)}
                  />
                </Form.Group>
                <p className="error__lable">{formErrors?.username}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="join__content">
                <Form.Group controlId="password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={status.password}
                    onChange={(e) => onInputChange(e)}
                  />
                </Form.Group>
                <p className="error__lable">{formErrors?.password}</p>
              </div>
            </Col>
            <Col lg="6">
              <div className="join__content">
                <Form.Group controlId="confirmpassword">
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    value={status.confirmpassword}
                    onChange={(e) => onInputChange(e)}
                  />
                </Form.Group>
                <p className="error__lable">{formErrors?.confirmpassword}</p>
              </div>
            </Col>
          </Row>
          <div
            className="join__extra__data"
            style={{ display: showOwnerExtraData }}
          >
            <Row>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="organizationName">
                    <Form.Control
                      placeholder="Organization Name"
                      value={status.organizationName}
                      onChange={(e) => onInputChange(e)}
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.organizationName}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  <Form.Select
                    value={status.organizationCountry}
                    id="organizationCountry"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>Select Your Country</option>
                    {getCountriesList().map((item, index) => (
                      <option value={item.code} key={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <p className="error__lable">
                    {formErrors?.organizationCountry}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="organizationWebsite">
                    <Form.Control
                      placeholder="Organization Website Link"
                      value={status.organizationWebsite}
                      onChange={(e) => onInputChange(e)}
                    />
                  </Form.Group>
                  <p className="error__lable">
                    {formErrors?.organizationWebsite}
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  <Form.Select
                    value={status.organizationType}
                    id="organizationType"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>Select Your Organization Type</option>
                    <option value={ORGANIZATION_TYPE_MEDIA_ORGANIZATION}>
                      Media organization
                    </option>
                    <option
                      value={ORGANIZATION_TYPE_FACT_CHECKING_ORGANIZATION}
                    >
                      Fact-checking organization
                    </option>
                    <option value={ORGANIZATION_TYPE_UNIVERSITY}>
                      University
                    </option>
                    <option value={ORGANIZATION_TYPE_NGO}>NGO</option>
                    <option value={ORGANIZATION_TYPE_OTHER}>Others</option>
                  </Form.Select>
                  <p className="error__lable">{formErrors?.organizationType}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content"></div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="organizationTypeOther">
                    <Form.Control
                      placeholder="Organization Type"
                      value={status.organizationTypeOther}
                      onChange={(e) => onInputChange(e)}
                      disabled={organizationTypeOtherDisabled}
                    />
                  </Form.Group>
                  <p className="error__lable">
                    {formErrors?.organizationTypeOther}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="join__extra__data"
            style={{ display: showWorkerExtraData }}
          >
            <Row>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="phone">
                    <Form.Control
                      placeholder="Phone"
                      type="number"
                      value={status.phone}
                      onChange={(e) => onInputChange(e)}
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.phone}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="facebook">
                    <Form.Control
                      placeholder="facebook"
                      value={status.facebook}
                      onChange={(e) => onInputChange(e)}
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.facebook}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="linkedin">
                    <Form.Control
                      placeholder="linkedin"
                      value={status.linkedin}
                      onChange={(e) => onInputChange(e)}
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.linkedin}</p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content join__form__select">
                  <Form.Select
                    value={status.gender}
                    id="gender"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>Gender</option>
                    <option value={GENDER_MALE}>Male</option>
                    <option value={GENDER_FEMALE}>Female</option>
                  </Form.Select>
                  <p className="error__lable">{formErrors?.gender}</p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="experience">
                    <Form.Control
                      placeholder="years of experience"
                      value={status.experience}
                      onChange={(e) => onInputChange(e)}
                      type="number"
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.experience}</p>
                </div>
              </Col>
              <Col lg="6"></Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content join__form__select">
                  <Form.Select
                    value={status.work}
                    id="work"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>
                      Are you Working with a Fact Checking organization?
                    </option>
                    <option value={YES}>Yes</option>
                    <option value={NO}>No</option>
                  </Form.Select>
                  <p className="error__lable">{formErrors?.work}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="organization">
                    <Form.Control
                      disabled={organizationDisabled}
                      placeholder="organization name"
                      value={status.organization}
                      onChange={(e) => onInputChange(e)}
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.organization}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content join__form__select">
                  <Form.Select
                    value={status.english}
                    id="english"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>English proficiency</option>
                    <option value={LANGUAGE_PROFICIENCY_BEGINNER}>
                      beginner
                    </option>
                    <option value={LANGUAGE_PROFICIENCY_INTERMEDIATE}>
                      intermediate
                    </option>
                    <option value={LANGUAGE_PROFICIENCY_UPPER_INTERMEDIATE}>
                      upper intermediate
                    </option>
                    <option value={LANGUAGE_PROFICIENCY_ADVANCED}>
                      advanced
                    </option>
                  </Form.Select>
                  <p className="error__lable">{formErrors?.english}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content join__form__select">
                  <Form.Select
                    value={status.arabic}
                    id="arabic"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>Arabic proficiency</option>
                    <option value={LANGUAGE_PROFICIENCY_BEGINNER}>
                      beginner
                    </option>
                    <option value={LANGUAGE_PROFICIENCY_INTERMEDIATE}>
                      intermediate
                    </option>
                    <option value={LANGUAGE_PROFICIENCY_UPPER_INTERMEDIATE}>
                      upper intermediate
                    </option>
                    <option value={LANGUAGE_PROFICIENCY_ADVANCED}>
                      advanced
                    </option>
                  </Form.Select>
                  <p className="error__lable">{formErrors?.arabic}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="join__content">
                  <Form.Select
                    value={status.topic}
                    id="topic"
                    type="select-one"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option value={" "}>Prefered topic</option>
                    <option value={PREFERED_TOPIC_POLITICS}>Politics</option>
                    <option value={PREFERED_TOPIC_SOCIAL}>Social</option>
                    <option value={PREFERED_TOPIC_SCIENCE}>Science</option>
                    <option value={PREFERED_TOPIC_HEALTH}>Health</option>
                    <option value={PREFERED_TOPIC_ENTERTAINMENT}>
                      Entertainment
                    </option>
                    <option value={PREFERED_TOPIC_OTHER}>other</option>
                  </Form.Select>
                  <p className="error__lable">{formErrors?.topic}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="join__content">
                  <Form.Group controlId="topicOther">
                    <Form.Control
                      placeholder="Prefered topic"
                      value={status.topicOther}
                      onChange={(e) => onInputChange(e)}
                      disabled={topicOtherDisabled}
                    />
                  </Form.Group>
                  <p className="error__lable">{formErrors?.topicOther}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Links to items you fact checked</div>
              </Col>
            </Row>
            <Row>
              <Form.Group controlId="linkone">
                <Form.Control
                  placeholder="Link 1"
                  value={status.linkone}
                  onChange={(e) => onInputChange(e)}
                />
              </Form.Group>
              <p className="error__lable">{formErrors?.linkone}</p>
            </Row>
            <Row>
              <Form.Group controlId="linktwo">
                <Form.Control
                  placeholder="Link 2"
                  value={status.linktwo}
                  onChange={(e) => onInputChange(e)}
                />
              </Form.Group>
              <p className="error__lable">{formErrors?.linktwo}</p>
            </Row>
            <Row>
              <Form.Group controlId="linkthree">
                <Form.Control
                  placeholder="Link 3"
                  value={status.linkthree}
                  onChange={(e) => onInputChange(e)}
                />
              </Form.Group>
              <p className="error__lable">{formErrors?.linkthree}</p>
            </Row>
          </div>
        </Container>

        {!show && (
          <Button variant="success-c" size="nd" block="true" type="submit">
            Join
          </Button>
        )}
      </Form>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unable to Join"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {someErrore}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="createButton" onClick={() => setShow(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default JoinForm;
