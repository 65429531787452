import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import AuthContext from "../../context/AuthProvider";
import { styles } from "./styles";
import { Row } from "react-bootstrap";
import { GetChatById, updateChatInfo } from "../../store/services/chat";
import { GetTaskById } from "../../store/services/tasks";
import { USER_TYPE_SELLER } from "../../store/constants/users";

const ChatWindow = (props) => {
  const URL = "wss://5jwarvkfhk.execute-api.eu-west-1.amazonaws.com/dev";
  const authContext = useContext(AuthContext);
  const [newMessage, setNewMessage] = useState("");
  const [chatList, setChatList] = useState([]);
  const [chatId, setChatId] = useState([]);
  const socket = useRef(new WebSocket(URL));

  const onSocketOpen = useCallback(() => {
    const email = authContext?.auth?.User?.attributes?.email;
    socket.current?.send(JSON.stringify({ action: "setEmail", email: email }));
  }, []);

  const onSocketClose = useCallback(() => {}, []);

  const onSocketMessage = useCallback((dataStr) => {
    const data = JSON.parse(dataStr);
    getChat();
  }, []);

  const onSocketSendMessage = useCallback((toEmail) => {
    const email = authContext?.auth?.User?.attributes?.email;
    socket.current?.send(
      JSON.stringify({ action: "sendmessage", email: toEmail })
    );
  }, []);

  const onConnect = useCallback(() => {
    try {
      if (socket.current?.readyState !== WebSocket.OPEN) {
        socket.current = new WebSocket(URL);
        socket.current.addEventListener("open", onSocketOpen);
        socket.current.addEventListener("close", onSocketClose);
        socket.current.addEventListener("message", (event) => {
          onSocketMessage(event.data);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getChat = async () => {
    try {
      const chatResponse = await GetChatById(props.taskId);
      const dataOftheChat = chatResponse["data"]["Items"][0]["chatData"];
      setChatList(dataOftheChat);
      setChatId(chatResponse["data"]["Items"][0]["chatId"]);
    } catch (error) {}
  };

  useEffect(() => {
    socket?.current?.close();
    getChat();
    onConnect();
  }, [setChatList, setChatId]);

  useEffect(() => {
    if (props.visible) {
      let scroll_to_bottom = document.getElementById("chat-listing");
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    }
  }, [props.visible, setChatList]);

  const update = async () => {
    try {
      const task = await GetTaskById(props.taskId);
      if (authContext?.auth?.UserType === USER_TYPE_SELLER) {
        const updatedCaht = await updateChatInfo(
          chatId,
          newMessage,
          authContext?.auth?.User?.attributes?.email,
          task["data"]["Item"]["owner"],
          new Date().toLocaleString()
        );
        onSocketSendMessage(task["data"]["Item"]["owner"]);
        setNewMessage("");
      } else {
        const updatedCaht = await updateChatInfo(
          chatId,
          newMessage,
          authContext?.auth?.User?.attributes?.email,
          task["data"]["Item"]["worker"],
          new Date().toLocaleString()
        );
        onSocketSendMessage(task["data"]["Item"]["worker"]);
        setNewMessage("");
      }
      await getChat();
    } catch (err) {
      console.log("----error---->", err);
    }
  };

  const onInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  return (
    <>
      <div
        className="transition-5"
        style={{
          ...styles.supportWindow,
          ...{ display: props.visible ? "block" : "none" },
        }}
      >
        <Row lg="5">
          <div id="chat-listing" className="col-md-8 col-xl-6 chat">
            <div className="card">
              {chatList.length >= 1 ? (
                <div>
                  {chatList.map((item, index) => (
                    <div key={index}>
                      {item["sender"] ===
                        authContext?.auth?.User?.attributes?.email && (
                        <div className="card-body msg_card_body">
                          <div
                            className="d-flex justify-content-end mb-4"
                            id="sender"
                          >
                            <div
                              className="msg_cotainer_send"
                              id="sender-container"
                            >
                              {item["contant"]}
                              <br />
                              <span className="msg_time_send">
                                {item["dateTime"]}
                              </span>
                            </div>
                            <div className="img_cont_msg"></div>
                          </div>
                        </div>
                      )}
                      {item["sender"] !==
                        authContext?.auth?.User?.attributes?.email && (
                        <div className="card-body msg_card_body">
                          <div
                            className="d-flex justify-content-start mb-4"
                            id="reciver"
                          >
                            <div className="img_cont_msg"></div>
                            <div className="msg_cotainer" id="">
                              <span className="msg_creator">
                                {item["sender"]}
                              </span>
                              <br />
                              {item["contant"]}
                              <br />
                              <span className="msg_time">
                                {item["dateTime"]}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <label>
                    <strong>No massage yet</strong>
                  </label>
                </>
              )}
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="input-group">
            <textarea
              name=""
              id="send-message"
              value={newMessage}
              onChange={(e) => onInputChange(e)}
              className="form-control type_msg"
              placeholder="Type your message..."
            ></textarea>
            <div
              onClick={() => {
                update();
              }}
              className="input-group-append"
            >
              <span className="input-group-text send_btn">
                <i className="fas fa-location-arrow"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
