import aws from "aws-sdk";
import config from "../../config.json";
import {
  TASK_ACTION_ACCEPT,
  TASK_ACTION_APPLY,
  TASK_ACTION_CREATE,
  USER_ACTION_ACCEPT,
  USER_ACTION_REJECT,
  USER_TYPE_SELLER,
} from "../constants";

aws.config.update({
  accessKeyId: config.AWS.ACCESS_KEY_ID,
  secretAccessKey: config.AWS.SECRET_ACCESS_KEY,
});

const ses = new aws.SES({ region: config.AWS.REGION });

export function sesTest(emailTo, emailFrom, message, name, subject) {
  const params = {
    Destination: {
      ToAddresses: [emailTo],
    },
    Message: {
      Body: {
        Text: {
          Data:
            "full name: " +
            name +
            "\n" +
            "email: " +
            emailFrom +
            "\n" +
            "message: " +
            "\n" +
            message,
        },
      },
      Subject: {
        Data: subject + " " + emailFrom,
      },
    },
    Source: emailTo,
  };
  return ses.sendEmail(params).promise();
}

export const sesSendEmail = (emailTo, emailFrom, message, subject) => {
  const params = {
    Destination: {
      ToAddresses: [emailTo],
    },
    Message: {
      Body: {
        Text: {
          Data: message,
        },
      },
      Subject: {
        Data: subject + " " + emailFrom,
      },
    },
    Source: emailFrom,
  };
  return ses.sendEmail(params).promise();
};
export const sesSendTemplateEmail = (
  emailTo,
  emailFrom,
  template,
  templateData
) => {
  const params = {
    Destination: {
      ToAddresses: emailTo,
    },
    Template: template,
    TemplateData: templateData,
    Source: emailFrom,
  };
  return ses.sendTemplatedEmail(params).promise();
};
export const sesCheckVerification = async (email, callback) => {
  var params = {
    Identities: [email],
  };
  ses.getIdentityVerificationAttributes(params, callback);
};
export const sesVerifyIdentity = (email) => {
  var params = {
    EmailAddress: email,
  };
  ses
    .verifyEmailIdentity(params)
    .promise()
    .then(() => {})
    .catch((err) => {
      console.error(err, err.stack);
    });
};
export const sesSandboxSendEmail = async (
  content,
  action,
  emailTo,
  userName
) => {
  let template = "";
  let templateData = "{ }";
  if (action === TASK_ACTION_CREATE) {
    template = "SES-Create-Template";
    templateData =
      '{ "owner":"' + userName + '", "date": "' + content.title + '" }';
  } else if (action === TASK_ACTION_APPLY) {
    template = "SES-Apply-Template";
    templateData =
      '{ "worker":"' + userName + '", "date": "' + content.title + '" }';
  } else if (action === TASK_ACTION_ACCEPT) {
    template = "SES-Accept-Template";
    templateData =
      '{ "owner":"' + userName + '", "date": "' + content.title + '" }';
  } else if (action === USER_ACTION_ACCEPT) {
    if (content.accountType === USER_TYPE_SELLER) {
      template = "SES-Accept-Worker-Template";
    } else {
      template = "SES-Accept-Owner-Template";
    }
  } else if (action === USER_ACTION_REJECT) {
    if (content.accountType === USER_TYPE_SELLER) {
      template = "SES-Reject-Worker-Template";
    } else {
      template = "SES-Reject-Owner-Template";
    }
  }
  sesSendTemplateEmail(
    emailTo,
    config.SES.SOURCE_EMAIL,
    template,
    templateData
  );
};
export const sesSandboxSendJoinEmail = async (emailTo, username, email) => {
  await sesCheckVerification(emailTo, function (err, data) {
    if (err) {
      console.log(err, err.stack);
    } else {
      if (!data.VerificationAttributes[emailTo]) {
        sesVerifyIdentity(emailTo);
      } else {
        const emailstatus =
          data.VerificationAttributes[emailTo]["VerificationStatus"];
        if (emailstatus === "Success") {
          let subject = "";
          let description = "";
          subject = "New Join Request";
          description =
            "you have new join request" +
            "\n" +
            "username: " +
            username +
            "\n" +
            "email: " +
            email;
          sesSendEmail(emailTo, config.SES.SOURCE_EMAIL, description, subject);
        } else {
          // "please verify your email"
        }
      }
    }
  });
};
