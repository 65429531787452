import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./SignIn.css";
import { Auth } from "aws-amplify";
import { AlertDialog } from "../../components/dialog/alertDialog";
import Loading from "../../components/loading/loading";
import {
  GetUserProfile,
  GetUserUnreadNotifications,
} from "../../store/services/user";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import { GetUserInvitationsTasks } from "../../store/services/tasks";
import getTasksList from "../../helpers/tasksList";
import {
  USER_PROFILE_STATUS_CREATED,
  USER_PROFILE_STATUS_NEW,
  USER_TYPE_BUYER,
  USER_TYPE_SELLER,
} from "../../store/constants";

const SigninForm = () => {
  const [show, setShow] = useState(false);
  const [dialogMassage, setDialogMassage] = useState({
    massageTitle: "Error",
    massageContent: "",
  });
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  const [status, setStatus] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState();

  const clearErrorState = () => {
    setStatus({
      ...status,
      ...{
        errors: {
          cognito: null,
        },
      },
    });
  };

  const validate = () => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!status.username) {
      errors.username = "Username is required!";
      document.getElementById("username").classList.add("is-danger");
    }
    if (!status.password) {
      errors.password = "Password is required!";
      document.getElementById("password").classList.add("is-danger");
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Form validation
    clearErrorState();
    //call validate
    const validationErrors = validate();
    setFormErrors(validationErrors);

    if (Object.keys(validationErrors)?.length === 0) {
      try {
        document.getElementById("loaing").classList.remove("hidden__input");
        const user = await Auth.signIn(status.username, status.password);
        const profile = await GetUserProfile(user?.attributes?.email);
        const getUnreadNotifications = await GetUserUnreadNotifications(
          user.attributes?.email
        );
        const notificationsList = getUnreadNotifications?.data?.Items || [];
        const userType = user.attributes["custom:AcountType"];
        let invitationsList = [];
        if (userType === USER_TYPE_SELLER && profile?.data?.Item) {
          const getUserInvitations = await GetUserInvitationsTasks(
            user.attributes?.email
          );
          invitationsList = getTasksList(getUserInvitations);
        }
        setAuth({
          User: user,
          AuthStatus: true,
          isAuthReady: true,
          UserType: user.attributes["custom:AcountType"],
          notifications: notificationsList,
          invitations: invitationsList,
        });
        if (profile?.data?.Item?.cognitoId["S"]) {
          localStorage.setItem("profile-status", USER_PROFILE_STATUS_CREATED);
          if (userType === USER_TYPE_SELLER) {
            navigate("/jobs/new");
          } else if (userType === USER_TYPE_BUYER) {
            navigate("/jobs/user-jobs");
          } else {
            navigate("/join-requests");
          }
        } else {
          navigate("/user-profile");
          localStorage.setItem("profile-status", USER_PROFILE_STATUS_NEW);
        }
      } catch (error) {
        setShow(true);
        let err = null;
        !error.message ? (err = { message: error }) : (err = error.message);
        setStatus({
          ...status,
          ...{
            errors: {
              ...status.errors,
              cognito: err,
            },
          },
        });
        setDialogMassage({
          massageTitle: "Error",
          massageContent: error.message,
        });
        document.getElementById("loaing").classList.add("hidden__input");
      }
    }
  };

  const onInputChange = (event) => {
    setStatus({ ...status, ...{ [event.target.id]: event.target.value } });
    setFormErrors({ ...formErrors, ...{ [event.target.id]: "" } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  return (
    <div className="logonform">
      <div className="hidden__input " id="loaing">
        <Loading />
      </div>
      <Form onSubmit={handleSubmit}>
        <h3>Sign in to AFCN</h3>
        <br></br>
        <Form.Group className="loinformgroup" controlId="username">
          <Form.Control
            type="text"
            placeholder="User Name \ Email"
            value={status.username}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.username}</p>
        <Form.Group className="loinformgroup" controlId="password">
          <Form.Control
            type="password"
            placeholder="Password"
            value={status.password}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.password}</p>

        <div className="forgotpassword">
          <p>
            <a href="/forgot-password">Forgot password?</a>
          </p>
        </div>

        <Button variant="success-c" size="nd" block="true" type="submit">
          Sign In
        </Button>
      </Form>

      {/* <Dialog
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unable to Signin"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {someErrore}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="createButton" onClick={() => setShow(false)}>
            Ok
          </Button>
          <Button>{"cancel"}</Button>
        </DialogActions>
      </Dialog> */}
      <AlertDialog
        show={show}
        title={dialogMassage.massageTitle}
        content={dialogMassage.massageContent}
        agreeAction={"Ok"}
        // disagreeAction={"cancel"}
        handleAgree={() => setShow(false)}
      ></AlertDialog>
      {/* <Alert show={show} variant="success">
        <Alert.Heading>Error Message</Alert.Heading>
        <input className="popUpInput" id="someErrore" value={someErrore} disabled variant="success" />
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
            Close
          </Button>
        </div>
      </Alert> */}
    </div>
  );
};
export default SigninForm;
