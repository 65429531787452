import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import "./ForgotPassword.css";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  const [status, setStatus] = useState({
    email: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false,
    },
  });

  const clearErrorState = () => {
    setStatus({
      ...status,
      ...{
        errors: {
          cognito: null,
          blankfield: false,
          passwordmatch: false,
        },
      },
    });
  };

  const validate = () => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!status.email) {
      errors.email = "email is required!";
      document.getElementById("email").classList.add("is-danger");
    }
    return errors;
  };
  const [formErrors, setFormErrors] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    clearErrorState();
    const validationErrors=validate();
    setFormErrors(validationErrors)
    // const error = Validate(event, this.state);
    // if (error) {
    //   this.setState({
    //     errors: { ...this.state.errors, ...error },
    //   });
    // }

    // AWS Cognito integration here
    try {
      await Auth.forgotPassword(status.email);
      navigate("/forgot-password-verification");
    } catch (error) {
    }
  };

  const onInputChange = (event) => {
    setStatus({ ...status, ...{ [event.target.id]: event.target.value } });
    // document.getElementById(event.target.id).classList.remove("is-danger");
    setFormErrors({ ...formErrors, ...{ [event.target.id]: '' } });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  return (
    <div className="forgotPasswordForm">
      <Form onSubmit={handleSubmit}>
        <h3>Forgot Your Password?</h3>
        <p>
          Please enter the email address associated with your account and we'll
          email you a password reset link.
        </p>
        <br></br>
        <Form.Group className="forgotPasswordGroup" controlId="email">
          <Form.Control
            type="text"
            placeholder="Email"
            value={status.email}
            onChange={(e) => onInputChange(e)}
            // required={true}
          />
        </Form.Group>
        <p className="error__lable">{formErrors?.email}</p>
        <Button variant="success-c" size="nd" block="true" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};
export default ForgotPassword;
