import React, { useContext, useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import JobListing from "../../components/jobListing/joblisting";
import "./UserJobs.css";
import {
  GetOwnerTasks,
  GetTaskProfileImage,
  GetWorkerTasks,
} from "../../store/services/tasks";
import getTasksList from "../../helpers/tasksList";
import AuthContext from "../../context/AuthProvider";
import { getTaskPrice } from "../../helpers/taskFromList";
import { Link } from "react-router-dom";
import Loading from "../../components/loading/loading";
import { USER_TYPE_BUYER, USER_TYPE_SELLER } from "../../store/constants/users";
import checkTaskOverdue from "../../helpers/taskHelper";
import NoJobsPlaceholder from "../../components/noJobsPlaceholder/noJobsPlaceholder";

const UserJobs = () => {
  const authContext = useContext(AuthContext);
  const [tasksList, setTasksList] = useState([]);

  const getTaskListByOwner = async (key) => {
    if (key === "overdue") {
      key = "open";
    }
    try {
      await GetOwnerTasks(key, authContext?.auth?.User?.attributes?.email).then(
        (tasks) => {
          const taskreaponce = getTasksList(tasks);
          setTasksList(taskreaponce);
        }
      );
    } catch (error) {}
  };
  const getTaskListByWorker = async (key) => {
    if (key === "overdue") {
      key = "open";
    }
    try {
      await GetWorkerTasks(
        key,
        authContext?.auth?.User?.attributes?.email
      ).then((tasks) => {
        const taskreaponce = getTasksList(tasks);
        setTasksList(taskreaponce);
      });
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        document.getElementById("loaing").classList.remove("hidden__input");

        if (authContext?.auth?.UserType === USER_TYPE_SELLER) {
          await GetWorkerTasks(
            type,
            authContext?.auth?.User?.attributes?.email
          ).then((tasks) => {
            const taskreaponce = getTasksList(tasks);
            setTasksList(taskreaponce);
          });
        } else {
          await GetOwnerTasks(
            type,
            authContext?.auth?.User?.attributes?.email
          ).then((tasks) => {
            const taskreaponce = getTasksList(tasks);
            setTasksList(taskreaponce);
          });
        }

        document.getElementById("loaing").classList.add("hidden__input");
      } catch (error) {}
    };

    if (authContext?.auth?.UserType === USER_TYPE_SELLER) {
      fetchData("open");
    } else {
      fetchData("new");
    }
  }, [setTasksList]);

  return (
    <div className="user__jobs">
      <div className="hidden__input " id="loaing">
        <Loading />
      </div>
      <div className="user__jobs__content">
        {authContext?.auth?.UserType === USER_TYPE_BUYER && (
          <div className="user__tasks__header">
            <a href="/creat-job/create">
              <Button variant="success-c" size="nd" block="true">
                Create Job
              </Button>
            </a>
          </div>
        )}
        <div className="tasks__tabs">
          <Tabs
            variant="pills"
            defaultActiveKey={
              authContext?.auth?.UserType === USER_TYPE_SELLER ? "open" : "new"
            }
            id="detailsTab"
            className="mb-3"
            justify
            onSelect={
              authContext?.auth?.UserType === USER_TYPE_SELLER
                ? (key) => getTaskListByWorker(key)
                : (key) => getTaskListByOwner(key)
            }
          >
            {authContext?.auth?.UserType === USER_TYPE_SELLER && (
              <Tab eventKey="open" title="Active Jobs">
                <div>
                  {tasksList.length > 0 ? (
                    tasksList.map(
                      (item, index) =>
                        !checkTaskOverdue(item.period) && (
                          <Link
                            className="job__link"
                            to="/creat-job/send"
                            state={{ job: item }}
                            key={item.id + " " + index}
                          >
                            <JobListing
                              title={item.title}
                              description={item.description}
                              price={getTaskPrice(item.price)}
                              time={item.period}
                              image={item.image}
                            />
                          </Link>
                        )
                    )
                  ) : (
                    <NoJobsPlaceholder />
                  )}
                </div>
              </Tab>
            )}
            {authContext?.auth?.UserType === USER_TYPE_BUYER && (
              <Tab eventKey="new" title="Open Jobs">
                <div>
                  {tasksList.length > 0 ? (
                    tasksList.map((item, index) => (
                      <Link
                        className="job__link"
                        to="/creat-job/accept"
                        state={{ job: item }}
                        key={item.id + " " + index}
                      >
                        <JobListing
                          title={item.title}
                          description={item.description}
                          price={getTaskPrice(item.price)}
                          time={item.period}
                          image={item.image}
                        />
                      </Link>
                    ))
                  ) : (
                    <NoJobsPlaceholder />
                  )}
                </div>
              </Tab>
            )}

            {authContext?.auth?.UserType === USER_TYPE_BUYER && (
              <Tab eventKey="open" title="Active Jobs">
                <div>
                  {tasksList.length > 0 ? (
                    tasksList.map(
                      (item, index) =>
                        !checkTaskOverdue(item.period) && (
                          <Link
                            className="job__link"
                            to="/creat-job/Closed"
                            state={{ job: item }}
                            key={item.id + " " + index}
                          >
                            <JobListing
                              title={item.title}
                              description={item.description}
                              price={getTaskPrice(item.price)}
                              time={item.period}
                              image={item.image}
                            />
                          </Link>
                        )
                    )
                  ) : (
                    <NoJobsPlaceholder />
                  )}
                </div>
              </Tab>
            )}

            <Tab eventKey="overdue" title="Overdue Jobs">
              <div>
                {tasksList.length > 0 ? (
                  tasksList.map(
                    (item, index) =>
                      checkTaskOverdue(item.period) && (
                        <Link
                          className="job__link"
                          to="/creat-job/send"
                          state={{ job: item }}
                          key={item.id + " " + index}
                        >
                          <JobListing
                            title={item.title}
                            description={item.description}
                            price={getTaskPrice(item.price)}
                            time={item.period}
                            image={item.image}
                          />
                        </Link>
                      )
                  )
                ) : (
                  <NoJobsPlaceholder />
                )}
              </div>
            </Tab>

            <Tab eventKey="delivered" title="Delivered Jobs">
              <div>
                {tasksList.length > 0 ? (
                  tasksList.map((item, index) => (
                    <Link
                      className="job__link"
                      to="/creat-job/Delivered"
                      state={{ job: item }}
                      key={item.id + " " + index}
                    >
                      <JobListing
                        title={item.title}
                        description={item.description}
                        price={getTaskPrice(item.price)}
                        time={item.period}
                        image={item.image}
                      />
                    </Link>
                  ))
                ) : (
                  <NoJobsPlaceholder />
                )}
              </div>
            </Tab>
            <Tab eventKey="closed" title="Completed Jobs">
              <div>
                {tasksList.length > 0 ? (
                  tasksList.map((item, index) => (
                    <Link
                      className="job__link"
                      to="/creat-job/Closed"
                      state={{ job: item }}
                      key={item.id + " " + index}
                    >
                      <JobListing
                        title={item.title}
                        description={item.description}
                        price={getTaskPrice(item.price)}
                        time={item.period}
                        image={item.image}
                      />
                    </Link>
                  ))
                ) : (
                  <NoJobsPlaceholder />
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UserJobs;
