import {
  faFileDownload,
  faFileExcel,
  faFilePdf,
  faFileText,
  faFileVideo,
  faFileWord,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GetS3FilePath } from "../../store/services/uploadImageToS3";
import "./AttatchmentList.css";

function AttatchmentList({ allFile }) {
  const getFileIcon = (fileName) => {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(fileName)[1];
    let fileType = ext.toLowerCase();
    if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
      return faImage;
    }

    if (fileType === "mp4") {
      return faFileVideo;
    }

    if (fileType === "doc" || fileType === "docx") {
      return faFileWord;
    }

    if (
      fileType === "csv" ||
      fileType === "xlsx" ||
      fileType === "xlsm" ||
      fileType === "xlsb" ||
      fileType === "xltx"
    ) {
      return faFileExcel;
    }

    if (fileType === "pdf") {
      return faFilePdf;
    }

    if (fileType === "text") {
      return faFileText;
    }

    return faFileDownload;
  };

  const getFileName = (fullPath) => {
    let fileLst = fullPath.split("/");
    return fileLst[fileLst.length - 1];
  };

  return (
    <div className="attachment__list__disabled__content mt-2">
      {allFile.map((item, index) => (
        <a href={GetS3FilePath(item.Key)} key={index}>
          <div className="attachment__list__file" key={index}>
            <p className="attachment__list__file__name">
              <FontAwesomeIcon icon={getFileIcon(item.Key)} />
            </p>
            <p className="attachment__list__file__name">
              {getFileName(item.Key)}
            </p>
          </div>
        </a>
      ))}
    </div>
  );
}

export default AttatchmentList;
