import React from "react";
import {Slider } from "../components";
const Home = () => {
  return (
    <div className="bgcolor">
      <Slider/>
    </div>
  );
};
export default Home;
